/* eslint-disable no-param-reassign,class-methods-use-this */
const getToken = () => {
  return document.querySelector('meta[name="csrf-token"]')
    ? document.querySelector('meta[name="csrf-token"]').getAttribute("content")
    : "";
};

class CsrfToken {
  constructor() {
    this.token = getToken();
  }

  refreshToken() {
    return getToken();
  }
};

export default {
  install(Vue) {
    Vue.CsrfToken = new CsrfToken();
    Vue.prototype.$csrfToken = Vue.CsrfToken.token;
  }
};
