<template>
  <section class="t-guest">
    <div class="t-guest__logo"></div>
    <div class="t-guest__content card">
      <div class="padding-lg">
        <h1 class="t-guest__heading">Sign up with your email</h1>
        <validation-observer v-slot="{ invalid }" ref="validationObserver">
          <form method="POST" action="/signup/delegate">
            <input type="hidden" name="_token" :value="$csrfToken" />
            <div class="o-formGroup">
              <label class="o-formGroup__label" for="email">Your email</label>
              <input-text
                v-model="username"
                name="username"
                validation-display-name="email"
                rules="email|required"
                id="email"
              />
            </div>

            <div class="o-formGroup">
              <label class="o-formGroup__label" for="password">Password</label>
              <create-password v-model="password" @validate="e => (passwordValid = e)" id="password"></create-password>
            </div>

            <validation-provider rules="required" v-slot="{ errors }" name="relationship_type">
              <label class="o-formGroup__label">Relationship to applicant(s)</label>
              <div class="p-signUp__relationshipRadios">
                <div class="o-formGroup --inlineInput">
                  <input type="radio" name="relationship_type" id="parent" v-model="relationshipType" value="parent" />
                  <label class="o-formGroup__label --inline --padLeft" for="parent">Parent / Guardian</label>
                </div>
                <div class="o-formGroup --inlineInput">
                  <input
                    type="radio"
                    name="relationship_type"
                    id="teacher"
                    v-model="relationshipType"
                    value="teacher"
                  />
                  <label class="o-formGroup__label --inline --padLeft" for="teacher">Teacher / Coach</label>
                </div>
              </div>
              <p v-if="errors.length" class="o-formGroup__error">{{ errors[0] }}</p>
            </validation-provider>

          <div class="o-formGroup">
            <label class="o-formGroup__label" for="first-name">Your first name</label>
            <input-text name="first_name" maxlength="40" validation-display-name="first name" v-model="firstName" rules="required" id="first-name"></input-text>
          </div>

          <div class="o-formGroup">
            <label class="o-formGroup__label" for="last-name">Your last name</label>
            <input-text name="last_name" maxlength="40" validation-display-name="last name" v-model="lastName" rules="required" id="last-name"></input-text>
          </div>

          <div class="o-formGroup">
            <label class="o-formGroup__label" for="dob">Your date of birth</label>
            <input-date-of-birth v-model="dateOfBirth" :min-age="18" :embed-hidden-input="true" id="dob" :server-errors="errors.dob"></input-date-of-birth>
          </div>

          <div class="o-formGroup --inlineInput">
            <input class="a-input --checkbox" type="checkbox" name="email_opt_in" v-model="emailOptIn" id="email-opt-in" />
            <label class="o-formGroup__label --inline --padLeft" for="email-opt-in">
              Opt in to receive emails about arts program opportunities for my students
            </label>
          </div>

          <validation-provider :rules="{ required: { allowFalse: false } }" v-slot="{ errors }" name="tos">
            <div class="o-formGroup --inlineInput">
              <input class="a-input --checkbox" :class="{'--invalid': errors.length}" v-model="tos" type="checkbox" name="tos" id="tos" />
              <label class="o-formGroup__label --inline --padLeft" for="tos">
                I agree to Acceptd’s <a href="https://getacceptd.com/terms-of-use" target="_blank">Terms of Use</a> and
                <a href="https://getacceptd.com/privacy-policy" target="_blank">Privacy Policy</a>
              </label>
              <p v-if="errors.length" class="o-formGroup__error --fullWidth">You must agree to terms of service</p>
            </div>
          </validation-provider>

            <div class="o-formGroup__actions">
              <a class="a-button --tertiary o-formGroup__action" href="/signup">Back</a>
              <button
                class="a-button --primary o-formGroup__action"
                type="submit"
                :disabled="invalid || !passwordValid"
              >
                Create Account
              </button>
            </div>
          </form>
        </validation-observer>
      </div>
    </div>
  </section>
</template>

<script>
import InputText from "Components/form/InputText.vue";
import InputDateOfBirth from "Components/form/InputDateOfBirth.vue";
import CreatePassword from "Components/sign-up/CreatePassword.vue";

export default {
  name: "SignUpParentTeacher",
  components: { InputText, CreatePassword, InputDateOfBirth },
  props: {
    oldInput: {
      required: false,
      default: () => ({})
    },
    errors: {
      required: false,
      default: () => ({})
    }
  },
  data() {
    return {
      username: this.oldInput.username,
      password: "", // password never sent back for security
      firstName: this.oldInput.first_name,
      lastName: this.oldInput.last_name,
      dateOfBirth: this.oldInput.dob,
      passwordValid: false,
      tos: Boolean(this.oldInput.tos),
      relationshipType: this.oldInput.relationship_type,
      emailOptIn: false
    };
  },
  computed: {
    hasErrors() {
      return Object.keys(this.errors).length;
    }
  },
  mounted() {
    if (this.hasErrors) {
      this.$refs.validationObserver.setErrors({
        "first name": this.errors.first_name,
        "last name": this.errors.last_name,
        email: this.errors.username,
        ...this.errors
      });
    }
  }
};
</script>
