<template>
  <validation-observer v-slot="{ invalid }" ref="validationObserver">
    <div>
      <div class="o-formGroup">
        <label class="o-formGroup__label" for="first-name">First name</label>
        <input-text
          name="first_name"
          v-model="firstName"
          rules="required"
          maxlength="40"
          validation-display-name="first name"
          id="first-name"
        />
      </div>

      <div class="o-formGroup">
        <label class="o-formGroup__label" for="last-name">Last name</label>
        <input-text
          name="last_name"
          v-model="lastName"
          rules="required"
          maxlength="40"
          validation-display-name="last name"
          id="last-name"
        />
      </div>

      <div class="o-formGroup">
        <label class="o-formGroup__label" for="dob">Date of birth</label>
        <input-date-of-birth
          v-model="dob"
          :min-age="minRegistrationAge"
          :embed-hidden-input="true"
          :server-errors="errors.dob"
          id="dob"
        ></input-date-of-birth>
      </div>

      <div class="o-formGroup">
        <label class="o-formGroup__label" for="location">
          Location
          <span id="location-why" class="o-formGroup__labelInfoTooltip">
            Why?
          </span>

          <!-- Location (Why?) Tooltip Content -->
          <tippy
            to-selector="#location-why"
            animation="scale"
            theme="light"
            arrow
          >
            <div>
              <p>
                  For privacy and regulatory compliance, Acceptd needs to know the country and state,
                  if you’re located in the USA, in which you reside. Your location can be kept up-to-date
                  in your account profile.
              </p>
            </div>
          </tippy>
        </label>
        <input-user-location
          id="location"
          render-hidden-input
          v-model="location"
        ></input-user-location>
      </div>

      <div class="o-formGroup">
        <label class="o-formGroup__label">
          Artistic disciplines
        </label>
        <p class="p-signUp__disciplineSubtext">Select one or two options.</p>
        <div class="chipContainer">
          <span
            v-for="category in artisticCategories"
            class="chip --accent --interest"
            :class="{ '--active': categoryIsSelected(category) }"
            @click="toggleCategorySelection(category)"
            role="button"
            :aria-label="category"
          >
            {{ category }}
          </span>
        </div>
        <p v-if="displayTooManyCategoriesError" class="o-formGroup__error">
          Maximum of {{ maxCategorySelections }} selections
        </p>
      </div>

      <hr class="border margin-bottom-sm">

      <div
        class="o-formGroup --offsetLeft"
        v-for="interest in availableInterests"
        :key="interest.id"
      >
        <label class="o-formGroup__label">{{ interest.label }}</label>
        <input-multiselect
          :max="3"
          :options="interest.options"
          @input="event => handleInterestInput(event, interest)"
        ></input-multiselect>
      </div>
      <div class="o-formGroup">
        <div class="o-formGroup --inlineInput --noMargin">
          <input class="a-input --checkbox" v-model="publicProfile" type="checkbox" name="public" id="public" />
          <label class="o-formGroup__label --inline --padLeft" for="public">
            Enable my <a href="https://getacceptd.com/your-acceptd-profile" target="_blank">Acceptd Profile</a>! 
            Allow arts opportunities to view my profile and contact me through Acceptd.
          </label>
        </div>
        <div class="o-formGroup --inlineInput">
          <input
            class="a-input --checkbox"
            v-model="smsOptIn"
            type="checkbox"
            name="sms_opt_in"
            id="sms-opt-in"
          />
          <label class="o-formGroup__label --inline --padLeft" for="sms-opt-in">
            Enable SMS Text Messaging. Receive reminders and promotional information via SMS messages.
          </label>
        </div>

        <div v-if="smsOptIn" class="margin-left-md width-60%">
          <validation-provider
            ref="phoneNumberProvider"
            name="phone number"
            :rules="phoneNumberRules"
            :disabled="!smsOptIn"
            v-slot="{ errors }"
          >
            <div class="o-formGroup">
              <label class="o-formGroup__label" for="phone-number">Phone Number</label>
              <vue-tel-input
                ref="vueTelInput"
                v-model="phoneNumber"
                :placeholder="'Mobile phone number'"
                :required="smsOptIn"
                :inputOptions="{ showDialCode: true }"
                :inputClasses="'custom-phone-input'"
                :defaultCountry="'US'"
                id="phone-number"
              />
              <p v-if="errors.length" class="o-formGroup__error">
                {{ errors[0] }}
              </p>
              <p v-if="!phoneNumberValid && phoneNumber" class="o-formGroup__error">
                Please enter a valid phone number.
              </p>
            </div>
          </validation-provider>
        </div>

        <validation-provider
          v-if="showTermsAgreement"
          :rules="{ required: { allowFalse: false } }"
          v-slot="{ errors }"
          name="tos"
        >
          <div class="o-formGroup --inlineInput">
            <input
              class="a-input --checkbox"
              :class="{ '--invalid': errors.length }"
              v-model="tos"
              type="checkbox"
              name="tos"
              id="tos"
            />
            <label class="o-formGroup__label --inline --padLeft" for="tos">
              I agree to Acceptd’s
              <a href="https://getacceptd.com/terms-of-use" target="_blank">
                Terms of Use
              </a>
              and
              <a href="https://getacceptd.com/privacy-policy" target="_blank">
                Privacy Policy
              </a>
            </label>
            <p v-if="errors.length" class="o-formGroup__error --fullWidth">
              You must agree to terms of service
            </p>
          </div>
        </validation-provider>
      </div>

      <div class="o-formGroup__actions">
        <button
          class="a-button --tertiary o-formGroup__action"
          type="button"
          @click="$emit('back')"
        >
          Back
        </button>

        <button
          data-test="create-account-button"
          class="a-button --primary o-formGroup__action"
          type="button"
          @click="emitDone"
          :disabled="invalid || isCreateAccountDisabled"
        >
          Create Account
        </button>
      </div>

      <!-- iterate each selected artistic category and bind it to a hidden input -->
      <input
        v-for="category in selectedCategories"
        type="hidden"
        :name="artisticAreasQuestionInputName"
        :value="category"
      />

      <!-- iterate each selected interest and bind them to grouped inputs named for the id (e.g. "q1234") -->
      <span v-for="(interest, key) in selectedInterests">
        <input
          v-for="selection in interest"
          type="hidden"
          :name="getInterestQuestionInputName(key)"
          :value="selection"
        />
      </span>
    </div>
  </validation-observer>
</template>

<script>
import InputDateOfBirth from "Components/form/InputDateOfBirth.vue";
import InputMultiselect from "Components/form/InputMultiselect.vue";
import InputText from "Components/form/InputText.vue";
import InputUserLocation from "../form/InputUserLocation.vue";
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

export default {
  name: "CreateStudent",
  components: {
    InputDateOfBirth,
    InputMultiselect,
    InputText,
    InputUserLocation,
    VueTelInput,
  },
  props: {
    questions: {
      required: true
    },
    minRegistrationAge: {
      type: Number,
      required: false,
      default: 16
    },
    oldInput: {
      required: false,
      default: () => ({})
    },
    errors: {
      required: false,
      default: () => ({})
    },
    maxCategorySelections: {
      type: Number,
      required: false,
      default: 2
    },
    showTermsAgreement: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      firstName: this.oldInput.first_name,
      lastName: this.oldInput.last_name,
      dob: this.oldInput.dob,
      tos: false,
      publicProfile: false,
      maxInterestsPerCategory: 3, // todo: prop?
      selectedCategories: [],
      selectedInterests: {},
      displayTooManyCategoriesError: false,
      location: undefined,
      smsOptIn: false,
      phoneNumber: "",
      phoneNumberValid: false,
    };
  },
  computed: {
    artisticCategories() {
      /** populates the choices for the chip selection buttons */
      return this.questions.options;
    },
    /** returns data for populating the sub drop-downs based on the selected categories */
    availableInterests() {
      return this.questions.children.filter(question => {
        return this.selectedCategories.includes(question.category);
      });
    },
    canSelectMoreCategories() {
      return this.selectedCategories.length < this.maxCategorySelections;
    },
    hasErrors() {
      return Object.keys(this.errors).length;
    },
    /** generates the specific input name expected by the backend for the artist areas question
     * @note this input name should be formatted as an array (e.g. "q12589[]")
     */
    artisticAreasQuestionInputName() {
      return "q" + this.questions.id + "[]";
    },
    phoneNumberRules() {
      return this.smsOptIn ? 'required' : '';
    },
    isCreateAccountDisabled() {
      const noCategoriesSelected = !this.selectedCategories.length;
      const locationNotSet = !(this.location && this.location.countryCode);
      const phoneInvalid = this.smsOptIn && !this.phoneNumberValid;
      return noCategoriesSelected || locationNotSet || phoneInvalid;
    },
  },
  watch: {
    phoneNumber(value) {
      if (this.smsOptIn && value) {
        this.validatePhoneNumber();
      } else {
        this.phoneNumberValid = !this.smsOptIn;
      }
    },
    smsOptIn(newVal) {
      if (!newVal) {
        this.phoneNumberValid = true;
      } else if (this.phoneNumber) {
        this.validatePhoneNumber();
      } else {
        this.phoneNumberValid = false;
      }
    },
  },
  mounted() {
    if (this.hasErrors) {
      this.$refs.validationObserver.setErrors({
        "first name": this.errors.first_name,
        "last name": this.errors.last_name,
        ...this.errors
      });
    }
    // re-populate any old input coming from the server for category selections
    if (this.oldInput && this.oldInput.hasOwnProperty("q12589")) {
      this.selectedCategories = this.oldInput.q12589;
    }
  },
  methods: {
    toggleCategorySelection(category) {
      if (this.categoryIsSelected(category)) {
        this.deselectCategory(category);
      } else {
        if (!this.canSelectMoreCategories) {
          return (this.displayTooManyCategoriesError = true);
        }
        this.selectedCategories.push(category);
      }

      return (this.displayTooManyCategoriesError = false);
    },

    categoryIsSelected(category) {
      return this.selectedCategories.includes(category);
    },

    /** maps interest selections to their id for for binding to hidden inputs */
    handleInterestInput(event, interest) {
      /** @note we have to use Vue's setter to ensure reactions in the DOM  */
      this.$set(this.selectedInterests, interest.id, event);
    },

    deselectCategory(category) {
      // Remove all interests that may have been selected for this category
      this.removeSelectedInterestsByCategory(category);
      // Deselect the category itself
      let index = this.selectedCategories.indexOf(category);
      this.selectedCategories.splice(index, 1);
    },

    getInterestQuestionById(id) {
      return this.questions.children.find(interest => {
        return interest.id == id;
      });
    },

    /** when deselecting a category, we also need to drop any of the associated interests a user may have selected */
    removeSelectedInterestsByCategory(category) {
      for (let key in this.selectedInterests) {
        const interest = this.getInterestQuestionById(key);
        if (interest && interest.category === category) {
          this.$delete(this.selectedInterests, key);
        }
      }
    },

    countInterestSelections(interest) {
      return this.selectedInterests.hasOwnProperty(interest)
        ? this.selectedInterests[interest].length
        : 0;
    },

    /* builds cryptic input names that are mapped to core config ids for the selected areas of interest */
    getInterestQuestionInputName(interestId) {
      return "q" + interestId + "[]";
    },

    emitDone(event) {
      this.$emit("done", event);
    },

    validatePhoneNumber() {
      const phoneNumber = parsePhoneNumberFromString(this.phoneNumber);
      this.phoneNumberValid = phoneNumber ? phoneNumber.isValid() : false;
    },
  }
};
</script>

<style>
  .custom-phone-input {
    height: inherit !important;
  }
</style>