<template>
  <section class="t-guest">
    <validation-observer v-slot="{ invalid }" ref="validationObserver">
      <div class="t-guest__logo"></div>
      <div class="t-guest__content card">
        <div class="padding-lg">
          <div class="o-userLocation">
            <div class="o-userLocation__section margin-bottom-md">
              <h2 class="t-guest__heading">
                Connect and get discovered. You can now see opportunities based on your location!
              </h2>
              <p class="t-guest__headingSubtext">
                We also need your location to comply with regulatory and privacy requirements. You can update your
                preferences later in your profile.
              </p>
            </div>
            <div class="o-userLocation__section margin-bottom-md">
              <input-user-location id="location" v-model="location"></input-user-location>
            </div>
            <div class="o-userLocation__section margin-bottom-md">
              <div class="o-formGroup --inlineInput --noMargin" v-if="!userHasDiscoverableProfile">
                <input class="a-input --checkbox" type="checkbox" name="public" id="public" v-model="public_profile" />
                <label class="o-formGroup__label --inline --padLeft" for="public">
                  Let arts opportunities know I’m interested! (Enable my <a href="https://getacceptd.com/your-acceptd-profile" target="_blank">Acceptd Profile</a>)
                </label>
              </div>
              <validation-provider
                :rules="{ required: { allowFalse: false } }"
                v-slot="{ errors }"
                name="tos"
                v-if="!userHasAcceptedTerms"
              >
                <div class="o-formGroup --inlineInput">
                  <input
                    class="a-input --checkbox"
                    :class="{ '--invalid': errors.length }"
                    v-model="tos"
                    type="checkbox"
                    name="tos"
                    id="tos"
                  />
                  <label class="o-formGroup__label --inline --padLeft" for="tos">
                    I agree to Acceptd’s
                    <a href="https://getacceptd.com/terms-of-use" target="_blank">Terms of Use</a>
                    and
                    <a href="https://getacceptd.com/privacy-policy" target="_blank">Privacy Policy</a>
                  </label>
                  <p v-if="errors.length" class="o-formGroup__error --fullWidth">
                    You must agree to terms of service
                  </p>
                </div>
              </validation-provider>
            </div>
            <div class="o-formGroup__actions">
              <button
                class="a-button --primary o-formGroup__action"
                :disabled="invalid || !(location && location.countryCode)"
                @click="submitLocation"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </validation-observer>
  </section>
</template>

<script>
import InputUserLocation from "./form/InputUserLocation.vue";
import Auth from "../auth";

export default {
  name: "UserLocation",
  components: {
    InputUserLocation
  },
  props: {
    userHasAcceptedTerms: {
      type: Boolean,
      required: true
    },
    userHasDiscoverableProfile: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      tos: false,
      public_profile: this.userHasDiscoverableProfile,
      auth: new Auth(),
      location: undefined
    };
  },
  watch: {
    countryCode(value) {
      this.selectedCountry = value;
    },
    postalCode(value) {
      this.providedPostalCode = value;
    }
  },
  methods: {
    submitLocation() {
      this.$store.dispatch("setBusy");
      this.$api
        .post("/location", {
          country_code: this.location.countryCode,
          postal_code: this.location.postalCode,
          latitude: this.location.latitude,
          longitude: this.location.longitude,
          accepts_terms: this.tos,
          public: this.public_profile
        })
        .then(response => {
          this.auth.handleToken(response.body.token, response.body.contexts);
          window.location.href = window.location.hash.substring(1) || "/dashboard?s=/terms";
        })
        .catch(error => {
          // checks if unverified so we can display a static link to re-send verification
          this.$store.dispatch("alert", {
            type: "error",
            message: error.body.message
          });
        })
        .finally(() => {
          this.$store.dispatch("setNotBusy");
        });
    }
  }
};
</script>
