<template>
  <transition>
    <div class="o-timeline">
      <template v-for="step in totalSteps">
        <div
          class="o-timeline__item"
          :class="{
            '--active': step === currentStep,
            '--complete': step < currentStep
          }"
          @click="setStep(step)"
        >
          {{ step }}
          <div class="o-timeline__itemCaption" @click.prevent>
            <slot :name="'itemCaption-' + step"></slot>
          </div>
        </div>

        <!-- add in a divider for all steps except the last -->
        <div
          v-if="step < totalSteps"
          class="o-timeline__divider"
          :class="{
            '--active': currentStep === (step + 1),
            '--complete': currentStep > (step + 1)
          }"
        ></div>
      </template>
    </div>
  </transition>
</template>

<script>
/**
 * Timeline: displays a horizontal timeline that applies active and completed classes for animating/displaying progress.
 * This component applies classes to the currently active (--active) step & any completed steps (--complete). Each step
 * item has a dynamic slot that can be used to insert a caption beneath an icon ("itemCaption-1", "itemCaption-2", etc.)
 * 
 * @prop {int} currentStep - the step that is currently active
 * @prop {int} totalSteps  - total number of steps
 *
 * @emits setStep - emitted when clicking on an item in the timeline to inform the parent of an intention to change current step
 */
export default {
  name: "Timeline",
  props: {
    currentStep: {
      type: Number,
      required: true
    },
    totalSteps: {
      type: Number,
      required: true
    }
  },
  methods: {
    setStep(step) {
      this.$emit("setStep", parseInt(step));
    }
  }
};
</script>
