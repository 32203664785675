<template>
  <div>
    <validation-provider
      :rules="rules"
      v-slot="{ errors }"
      :name="validationDisplayName ? validationDisplayName : name"
      :mode="validationMode"
    >
      <multiselect
        v-model="selectedObject"
        :label="selectValue"
        :select-label="null"
        :selected-label="null"
        deselect-label=""
        :options="options"
        :searchable="searchable"
        :close-on-select="true"
        :max="false"
        :placeholder="placeholder"
        :multiple="false"
        :allow-empty="allowEmpty"
        :class="{ '--wrapping': wrapOptions }"
      ></multiselect>

      <slot name="belowInput"></slot>

      <slot v-if="errors.length" name="errors" :errors="errors">
        <p class="o-formGroup__error" v-html="errors[0]"></p>
      </slot>
    </validation-provider>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import FormInput from "Components/form/FormInput";

export default {
  name: "InputSelect",
  extends: FormInput,
  components: { Multiselect },
  props: {
    value: {
      required: false,
      type: [String, Number]
    },
    options: {
      required: true
    },
    placeholder: {
      required: false,
      default: "Pick a value"
    },
    selectOption: {
      type: String,
      default: "option"
    },
    selectValue: {
      type: String,
      default: "value"
    },
    allowEmpty: {
      type: Boolean,
      default: true
    },
    wrapOptions: {
      type: Boolean,
      default: false
    },
    searchable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      selectedObject: null,
      newValue: ""
    };
  },
  mounted() {
    if (this.value) {
      this.selectedObject = this.options.find(option => option[this.selectOption] === this.value) || null;
    }
  },
  watch: {
    value(value) {
      if (value) {
        this.selectedObject = this.options.find(option => option[this.selectOption] === value) || null;
      } else {
        this.selectedObject = null;
      }
    },
    selectedValue(value) {
      this.$emit("input", value);
    }
  },
  computed: {
    selectedValue() {
      if (this.selectedObject) {
        return this.selectedObject[this.selectOption];
      }
      return "";
    }
  }
};
</script>
