<template>
  <div class="o-formGroup">
    <input-revealable-text
      :value="value"
      rules="required"
      validation-display-name="password"
      validation-mode="aggressive"
      :id="id"
      :name="name"
      v-bind="$attrs"
      v-on="$listeners"
      @input="emitValidate"
      @blur="handleBlur"
    ></input-revealable-text>

    <p v-if="showInvalidWarning" class="o-formGroup__error">Password does not meet minimum requirements</p>

    <ul class="m-passwordRequirements">
      <li class="m-passwordRequirements__requirement" :class="{ '--valid': validLength }" ref="length">
        <nobr>{{ minPasswordLength }} characters minimum</nobr>
      </li>
      <li class="m-passwordRequirements__requirement" :class="{ '--valid': hasUppercase }" ref="uppercase">
          <nobr>One uppercase letter</nobr>
      </li>
      <li class="m-passwordRequirements__requirement" :class="{ '--valid': hasLowercase }" ref="lowercase">
          <nobr>One lowercase letter</nobr>
      </li>
      <li class="m-passwordRequirements__requirement" :class="{ '--valid': hasSpecialChar }" ref="special">
          <nobr>One number or special char</nobr>
      </li>
    </ul>
  </div>
</template>

<script>
/**
 * CreatePassword: renders a password input w/ 4 icon+text items that will change state to indicate when a password
 * requirement has been fulfilled (e.g. when minimum length reached, the length requirement indicator turns green)
 *
 * @prop string value          - required value prop to pass down to input (for use with v-model)
 * @prop int minPasswordLength - optional min password length requirement (default 8)
 * @emits event validate       - emits validate event on input returning current validity status (true/false)
 */
import InputText from "Components/form/InputText.vue";
import InputRevealableText from "Components/form/InputRevealableText.vue";

export default {
  name: "CreatePassword",
  components: { InputText, InputRevealableText },
  props: {
    value: {
      type: String,
      required: true
    },
    minPasswordLength: {
      type: Number,
      default: 8
    },
    id: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: "password"
    }
  },
  data() {
    return {
      showInvalidWarning: false
    }
  },
  computed: {
    validLength() {
      return this.value.length >= this.minPasswordLength;
    },
    hasUppercase() {
      return this.value.match(/[A-Z]+/) !== null;
    },
    hasLowercase() {
      return this.value.match(/[a-z]+/) !== null;
    },
    hasSpecialChar() {
      // todo: better solution? (this just checks if password contains anything NOT a letter)
      return this.value.match(/[^a-zA-Z\s]+/) !== null;
    },
    passwordValid() {
      return (
        this.validLength &&
        this.hasUppercase &&
        this.hasLowercase &&
        this.hasSpecialChar
      );
    }
  },
  methods: {
    emitValidate() {
      this.$emit("validate", this.passwordValid);
    },
    handleBlur() {
      // we show the invalid warning if a password exists and is not valid (otherwise VeeValidate handles required)
      this.showInvalidWarning = this.value && !this.passwordValid;
    }
  },
  watch: {
    value(val) {
      this.emitValidate();

      if (this.passwordValid) {
        this.showInvalidWarning = false;
      }
    }
  }
};
</script>
