export default class ApiError extends Error {
  constructor(payload, ...params) {
    super(...params);

    this.payload = payload;
    this.status = this.statusCode();
    this.body = this.payloadBody();
    this.message = this.friendlyMessage();

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError);
    }
  }

  forbidden() {
    return this.statusCode() === 403;
  }

  validationError() {
    return this.statusCode() === 422;
  }

  serverError() {
    return this.statusCode() >= 500;
  }

  statusCode() {
    /* eslint-disable */
    return this.payload && this.payload.hasOwnProperty("status") && !Number.isNaN(this.payload.status)
      ? this.payload.status
      : null;
    /* eslint-enable */
  }

  friendlyMessage() {
    switch (this.statusCode()) {
      case 400:
        return this.body.message || "Bad request: Please check data and try again.";
      case 401:
        return "Your session has expired. Please refresh the page and login.";
      case 403:
        return this.body.message || "Invalid request: Forbidden.";
      case 404:
        return "Requested resource not found.";
      case 409:
        return this.body.message || "Input is invalid. Please fix errors and try again.";
      case 419:
        return "The page has expired due to inactivity. Please refresh page and try again.";
      case 422:
        return "Input is invalid. Please fix errors and try again.";
      case 500:
        return "Something went wrong. Please try again and contact support if this issue persists.";
      default:
        return this.body.message || "Something went wrong. Please refresh and try again.";
    }
  }

  payloadBody() {
    // eslint-disable-next-line no-prototype-builtins
    return this.payload && this.payload.hasOwnProperty("body") ? this.payload.body : {};
  }

  getErrors() {
    if (this.validationError()) {
      return this.payload.body.errors;
    }
    if (this.serverError()) {
      return { server: [this.message] };
    }
    return { default: [this.message] };
  }
}
