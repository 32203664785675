import Router from "LegacyJS/global/routingutility";
import ApiError from "./ApiError";

const standardResponse = r => {
  return r.json().then(data => {
    const response = {
      status: r.status,
      body: data,
      message: r.statusText
    };

    if (r.ok) {
      return response;
    }

    // redirect user to login when response indicates user unauthenticated.
    if (response.status && response.status === 401) {
      window.location.replace(`/logout#${window.location.pathname + window.location.search}`);
      return response;
    }

    throw response;
  });
};

const standardError = error => {
  throw new ApiError(error);
};

class Api {
  constructor() {
    this.router = new Router();
    this.getToken = Function.prototype; // noop
    this.getContext = Function.prototype; // noop
  }

  /**
   * Set CSRF Token
   *
   * @param {string} token
   * @returns void
   */
  setCSRFToken(token) {
    this.csrfToken = token;
  }

  /**
   * Set the function that should be called to get the JWT. e.g. Session.getToken()
   *
   * @param getterFn
   * @param _this
   */
  setJWTGetter(getterFn, _this) {
    this.getToken = (...args) => getterFn.call(_this, ...args);
  }

  /**
   * Set the function that should be called to get the User's context id. e.g. Session.context.id()
   *
   * @param getterFn
   * @param _this
   */
  setContextGetter(getterFn, _this) {
    this.getContext = (...args) => getterFn.call(_this, ...args);
  }

  /**
   * Get API call.
   *
   * @param {string} uri
   * @param {object} data
   * @param {object} options
   * @returns {Promise<T>}
   */
  get = async (uri, data = {}, options = {}) => {
    const params = Object.keys(data).length
      ? Object.keys(data)
          .map(key => `${key}=${encodeURIComponent(data[key])}`)
          .join("&")
      : null;

    const url = params ? `${uri}?${params}` : uri;

    return fetch(this.router.getApiUrl(url), {
      method: "GET",
      credentials: "same-origin",
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
        "Context-Id": this.getContext(),
        Accept: "application/json",
        "Content-Type": "application/json",
        ...(options.headers || {})
      }
    })
      .then(standardResponse)
      .catch(standardError);
  };

  /**
   * Put API call.
   *
   * @param {string} uri
   * @param {object} data
   * @param {object} options
   * @returns {Promise<T>}
   */
  put = async (uri, data = {}, options = {}) => {
    return fetch(this.router.getApiUrl(uri), {
      body: JSON.stringify(data),
      credentials: "same-origin",
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${this.getToken()}`,
        "Content-Type": "application/json",
        "Context-Id": this.getContext(),
        "X-CSRF-TOKEN": this.csrfToken,
        ...(options.headers || {})
      }
    })
      .then(standardResponse)
      .catch(standardError);
  };

  /**
   * Post API call.
   *
   * @param {string} uri
   * @param {object} data
   * @param {object} options
   * @returns {Promise<T>}
   */
  post = async (uri, data = {}, options = {}) => {
    return fetch(this.router.getApiUrl(uri), {
      body: JSON.stringify(data),
      credentials: "same-origin",
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${this.getToken()}`,
        "Content-Type": "application/json",
        "Context-Id": this.getContext(),
        "X-CSRF-TOKEN": this.csrfToken,
        ...(options.headers || {})
      }
    })
      .then(standardResponse)
      .catch(standardError);
  };

  /**
   * Delete API call.
   *
   * @param {string} uri
   * @param {object} data
   * @param {object} options
   * @returns {Promise<T>}
   */
  delete = async (uri, data = {}, options = {}) => {
    return fetch(this.router.getApiUrl(uri), {
      body: JSON.stringify(data),
      credentials: "same-origin",
      method: "DELETE",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${this.getToken()}`,
        "Content-Type": "application/json",
        "Context-Id": this.getContext(),
        "X-CSRF-TOKEN": this.csrfToken,
        ...(options.headers || {})
      }
    })
      .then(standardResponse)
      .catch(standardError);
  };

  /**
   * Send forgot password request to API
   *
   * @param username
   * @returns {Promise<T>}
   */
  sendForgotPassword = async username => {
    return fetch(this.router.getApiUrl("/api/users/send_forgot_password"), {
      body: JSON.stringify({ username }),
      credentials: "same-origin",
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": this.csrfToken
      }
    })
      .then(standardResponse)
      .catch(standardError);
  };

  /**
   * Send change password request to API
   *
   * @param hash
   * @param password
   * @returns {Promise<T>}
   */
  changePassword = async (hash, password) => {
    return fetch(this.router.getApiUrl("/api/users/change_password"), {
      body: JSON.stringify({ hash, password }),
      credentials: "same-origin",
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": this.csrfToken
      }
    })
      .then(standardResponse)
      .catch(standardError);
  };
}

export default new Api();
