<template>
  <div
    class="user-nav-container"
    :class="{ 'header-subnav-expanded': open }"
  >
    <button
      @click="toggleOpen"
      type="button"
      class="user-nav-button"
      aria-pressed="false"
      :aria-expanded="open ? 'true': 'false'"
      aria-controls="user-nav-menu"
      aria-haspopup="true"
      role="button"
    >
      <span class="user-headshot-container">
        <img
          class="user-headshot"
          :src="headshotSrc"
          :alt="fullName"
        />
      </span>
      <span class="user-greeting-icon">
        <span class="user-greeting">Hi {{ firstName }}</span>
      </span>
    </button>
    <div
      id="user-nav-menu"
      class="user-nav-menu header-subnav"
      aria-hidden="false"
    >
      <div class="user-menu-panel">
        <ul class="user-menu-list" role="menu" aria-label="User Menu">

          <slot name="menuLinks"></slot>

          <li role="menuitem">
            <a href="/logout" class="user-menu-link">
              Logout
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserDropdownMenu",
  props: {
    firstName: {
      type: String,
      required: true
    },
    lastName: {
      type: String,
      required: true
    },
    headshot: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      open: false,
      defaultHeadshot: "/images/svg/profile.svg"
    };
  },
  computed: {
    headshotSrc() {
      return this.headshot ? this.headshot : this.defaultHeadshot;
    },
    fullName() {
      return `${this.firstName} ${this.lastName}`.trim();
    }
  },
  methods: {
    toggleOpen() {
      this.open = !this.open;
      this.$emit('toggle', this.open);
    }
  }
};
</script>
