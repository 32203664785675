<template>
  <section class="t-guest">
    <a class="t-guest__logo" href="/">
      <span class="screen-reader-text">
        {{ $t("global.header.navigation.logo_label") }}
      </span>
    </a>

    <div class="t-guest__content card">
      <div class="padding-lg">
        <h1 class="t-guest__heading">{{ $t("user.forgotpassword.header") }}</h1>
          <!--  Success Submission Area  -->
          <div v-if="isSuccessfullySubmitted">
            <p>
              {{ $t("user.forgotpassword.usernameDescription") + " " }}
              <strong>{{ username }}</strong>
            </p>
            <br>
            <p v-html="noEmailMessage"></p>
          </div>

          <!--  Input Area  -->
          <div v-else>
            <form @submit.prevent="submit" novalidate="">
              <p class="o-formGroup">{{ $t("user.forgotpassword.instructions") }}</p>
              <div class="o-formGroup">
                <label for="forgot-password-username" class="o-formGroup__label --required">{{ $t("user.forgotpassword.emailLabel") }}</label>
                <input-text
                  v-model="username"
                  rules="required|email"
                  validation-display-name="email"
                  id="forgot-password-username"
                  name="forgot-password-username"
                  @blur="isTouched = true"
                ></input-text>
              </div>
              <p v-if="serverError" class="o-formGroup o-formGroup__error">{{ serverError }}</p>
              <button class="a-button --primary --block" type="submit" :disabled="!isValid || isSubmitting" ref="submitButton">
                {{ $t("user.forgotpassword.reset") }}
              </button>
            </form>
          </div>
          <a class="t-guest__secondaryC2a" :href="router.getApiUrl('/login')">{{ $t("user.forgotpassword.userLoginUrl") }}</a>
      </div>
    </div>

    <p class="t-guest__subtext">
      Don't have an Acceptd account?
      <a href="/signup">Sign Up!</a>
    </p>
  </section>
</template>

<script>
import Router from "js/global/routingutility";
import InputText from "Components/form/InputText.vue";

export default {
  name: "ForgotPassword",
  components: { InputText },
  data() {
    return {
      isSuccessfullySubmitted: false,
      isSubmitting: false,
      isTouched: false,
      username: "",
      router: new Router(),
      serverError: ''
    };
  },
  computed: {
    noEmailMessage() {
      // had to move this here so it would get loaded at the right time
      return this.$t("user.forgotpassword.support", {
        create_account_link: this.router.getApiUrl("/signup")
      });
    },
    isValid() {
      return !!(
        this.username &&
        this.username.length < 255 &&
        this.username.match(
          "^[a-zA-Z0-9.!#$%&amp;'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
        )
      );
    }
  },
  methods: {
    submit() {
      this.isSubmitting = true;
      this.$store.dispatch("setBusy");
      this.$api
        .post("/api/users/send_forgot_password", { username: this.username })
          .then(response => {
            this.isSuccessfullySubmitted = true;
          })
          .catch(error => {
            this.serverError = error.message;
            this.isSuccessfullySubmitted = false;
          })
          .finally(() => {
            this.isSubmitting = false;
            this.$store.dispatch("setNotBusy");
          });
    }
  }
};
</script>
