<template>
    <div>
        <div v-for="(filter, index) in filters" class="selector-container" v-if="filters.length">
            <div class="program-filter-header">
                <label :for="`program-filter-select-${filter.name}`" class="program-filter-label" :class="{'screen-reader-text': hasSingleFilter }">{{ filter.name }}:</label>
                <button :aria-label="`Remove ${filter.name} filter`" class="program-filter-clear" @click="clearFilter(filter.name)" v-if="isDisabled(filter.name)">Clear</button>
            </div>
            <vue-multiselect v-model="selectedFilters[filter.name]"
                             class="--wrapping"
                             :show-labels=false
                             :id="`program-filter-select-${filter.name}`"
                             :placeholder="`Select ${filter.name}`"
                             autocomplete="off"
                             @input="changeFilter"
                             :options="Array.from(filter.options)"
                             :disabled="isDisabled(filter.name)">
            </vue-multiselect>
        </div>
        <div class="selector-container">
            <label for="program-selector-select" class="programs-title programs-label-text" :class="{'screen-reader-text': hasSingleFilter }">Select a Program</label>
            <vue-multiselect :value="selectedProgram"
                             class="--wrapping"
                             v-if="availablePrograms.length"
                             :show-labels=false
                             placeholder="Select Program"
                             track-by="id"
                             id="program-selector-select"
                             @input="selectProgram"
                             label="name"
                             autocomplete="off"
                             :options="availablePrograms">
            </vue-multiselect>
            <p v-else class="no-programs-results">There are no programs currently available, please check again at a later date.</p>
        </div>
        <div class="program-apply-button-region"
             role="region"
             aria-live="assertive"
             aria-labelledby="program-apply-button">
            <a v-if="selectedProgram && selectedProgram.is_startable"
               id="program-apply-button"
               class="program-apply-button"
               :href="applyToProgramLink"
            >
                {{ `${ctaButton} ${selectedProgram.name}` }}
            </a>
        </div>
    </div>
</template>

<script>
    import VueMultiselect from "vue-multiselect";
    import {mapActions, mapState} from 'vuex';

    export default {
        name: "ProgramSelect",
        props: {
            ctaButton: {
                type: String,
                required: true,
            },
        },
        components: {
            VueMultiselect,
        },
        data() {
            return {
                selectedFilters: {},
            };
        },
        computed: {
            ...mapState('organizationLanding', ['selectedProgram', 'programs', 'isDelegate']),
            filters() {
                // This implementation is not great. First we are grouping up the filters
                // by name, then once we have the groups, we loop over again to sort the grouped
                // list by the filters options order.
                const filterOrder = [];
                const filterMap = {};
                let filterCount = 0;
                this.availablePrograms.forEach((program) => {
                    program.filters
                        .forEach((filter) => {
                            if (!filterMap.hasOwnProperty(filter.name)) {
                                filterMap[filter.name] = new Set();
                                filterOrder[filter.group_order] = filter.name;
                            }
                            filterMap[filter.name].add(filter);
                    })
                });

                const sortedFilters = [];

                filterOrder.forEach((filterName) => {
                    const filterSet = filterMap[filterName];
                    sortedFilters.push({ name: filterName, options: new Set(Array.from(filterSet).sort((a, b) => a.order - b.order).map((filter) => filter.value))});
                });

                return sortedFilters;
            },
            availablePrograms() {
                let matchingPrograms = this.programs;

                const selectedNames = Object.keys(this.selectedFilters);

                if (selectedNames.length > 0) {
                    matchingPrograms = this.programs.filter((program) => {
                        // we are looking for how many of the selected filters match this programs filters
                        // if we have the same number of matches as number of selected, then it's a complete match.
                        const matches = selectedNames.filter((name) => {
                            const selectedValue = this.selectedFilters[name];

                            const hasMatchingValue = (f) => f.name === name && f.value === selectedValue;

                            const matchingPrograms = program.filters.filter(hasMatchingValue);

                            // this program matches the selected filter if at least one of its filters is a match.
                            // the selectedValue is set to null when a filter that was previously selected has been deselected
                            // which means its an automatic match.
                            return matchingPrograms.length > 0 || selectedValue == null;
                        });

                        return matches.length === selectedNames.length;
                    });
                }

                return matchingPrograms;
            },
            applyToProgramLink() {
                if (!this.selectProgram){
                    return "";
                }

                if (this.isDelegate) {
                    return `/delegate/applicationstart/${this.selectedProgram.id}`
                }

                return `/applicationstart/${this.selectedProgram.id}?org=-1`;
            },
            hasSingleFilter() {
                return Object.keys(this.filters).length < 2;
            }
        },
        methods: {
            ...mapActions('organizationLanding', ['selectProgram']),
            changeFilter() {
                if (this.selectedProgram === null) return;

                const selectedProgramIsAnOption =
                        this.availablePrograms.filter((program) => program.id === this.selectedProgram.id).length;

                if (!selectedProgramIsAnOption) {
                    this.selectProgram(null);
                }
            },
            isDisabled(filter) {
                return !this.hasSingleFilter && this.selectedFilters[filter] && this.selectedFilters[filter] !== null;
            },
            clearFilter(filter) {
                this.selectedFilters[filter] = null;
            }
        },
    }
</script>
