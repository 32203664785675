<template>
   <div class="cover-image" :class="{ 'has-images': hasImages }">
       <div class="cover-image__image fade-in" v-for="(image, i) in imageUrls" :style="styles(i)"></div>
   </div>
</template>

<script>
    export default {
        name: "CoverCarousel",
        data() {
            return {
                index: 0,
                interval: null,
            }
        },
        props: {
            imageUrls: {
                required: true,
                type: Array,
            }
        },
        computed: {
            styles() {
                return (index) => ({
                    backgroundImage: "url('" + this.imageUrls[index] + "')",
                    opacity: index === this.index ? 1 : 0,
                });
            },
            hasImages() {
                return !!this.imageUrls.length;
            }
        },
        mounted() {
            this.interval = setInterval(() => {
                this.index = (this.index + 1) % this.imageUrls.length;
            }, 5000);
        },
        beforeDestroy() {
            if (this.interval !== null) {
                clearInterval(this.interval);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .organization-view-header .cover-image.has-images::after {
        background-image: linear-gradient(to top, rgba(0, 0, 0, 0.9), transparent);
    }

    .cover-image__image {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        transition: opacity 350ms ease-in;
        background-size: cover;
        background-color: transparent;
        background-position: 50%;
        background-repeat: no-repeat;
    }
</style>
