/**
 * FormInput.js: A renderless component that can be extended by other custom form components
 * to provide v-model compatibility and expose props for validation, etc.
 */
export default {
  name: "FormInput",
  render: () => null,
  props: {
    /**
     * A bound value for the input. Can be used with <code>v-model</code> syntax.
     */
    value: {
      required: true
    },

    /**
     * A name for the input element.
     * @deprecated
     */
    name: {
      type: String,
      required: false
    },

    /**
     * Additional classes to apply the input (because "class" is a reserved word)
     * @deprecated
     */
    classes: {
      type: String,
      required: false,
      default: ""
    },

    /**
     * Specify VeeValidate validation rules for the input. https://logaretm.github.io/vee-validate/api/rules.html
     */
    rules: {
      type: String,
      required: false,
      default: ""
    },

    /**
     * Determines VeeValidate will run validation. https://logaretm.github.io/vee-validate/guide/interaction-and-ux.html#interaction-modes
     */
    validationMode: {
      required: false,
      default: "eager"
    },

    /**
     * A displayable name for the field that VeeValidate can use for the error message.
     * For example: for an input with "name=first_name", VeeValidate will use the underscored name in the displayed message.
     * This prop can be set to "First name" for human readable display.
     */
    validationDisplayName: {
      type: String,
      required: false
    },

    /**
     * Overrides the VeeValidate validation error message.
     */
    errorMessage: {
      type: String,
      default: null
    }
  },
  computed: {
    /**
     * This property allows us to customize certain event listeners (and let other events simply bubble up)
     * @docs https://vuejs.org/v2/guide/components-custom-events.html
     */
    inputListeners() {
      const vm = this;
      // We add all the listeners and merge them with an object containing any overrides
      return Object.assign({}, this.$listeners, {
        input(event) {
          // Override the input event to emit only a value so we can attach to v-model
          vm.$emit("input", event.target.value);
        }
      });
    }
  }
};
