<template>
  <section class="t-guest">
    <a class="t-guest__logo" href="/">
      <span class="screen-reader-text">
        {{ $t("global.header.navigation.logo_label") }}
      </span>
    </a>

    <div class="t-guest__content card">
      <div class="padding-lg">
        <h1 class="t-guest__heading">{{ $t("user.changepassword.header") }}</h1>
        <div class="o-formGroup">
          <label class="o-formGroup__label" for="change-password">{{ $t("user.changepassword.new") }}</label>
          <create-password v-model="password" @validate="e => passwordValid = e" id="change-password"></create-password>
        </div>

        <div class="o-formGroup__actions">
          <button class="a-button --primary --block" type="button" :disabled="!passwordValid" @click="update">{{ $t("user.changepassword.save") }}</button>
        </div>
      </div>
    </div>

    <p class="t-guest__subtext">
      {{ $t("user.userlogin.swap.question") }}
      <a href="/signup">{{ $t("user.userlogin.swap.link") }}</a>
    </p>
  </section>
</template>

<script>
import i18next from "i18next";
import RoutingUtility from "js/global/routingutility";
import ErrorUtility from "../error";
import CreatePassword from "Components/sign-up/CreatePassword.vue";

const errorUtility = new ErrorUtility();

export default {
  name: "ChangePassword",
  components: { CreatePassword },
  props: {
    hash: {
      required: true
    }
  },
  data() {
    return {
      isSubmitting: false,
      password: "",
      route: new RoutingUtility(),
      passwordValid: false
    };
  },
  methods: {
    update() {
      if (!this.passwordValid) return;

      this.isSubmitting = true;
      this.$store.dispatch("setBusy");

      this.$api
        .changePassword(this.hash, this.password)
        .then(response => {
          this.$store.dispatch("alert", {
            type: "success",
            message: this.$t(response.body.return_code)
          });
          // delay a bit before redirecting to allow growl message to be displayed
          setTimeout(() => {
            window.location.href = this.route.getApiUrl("/login");
          }, 500);
        })
        .catch(error => {
          this.$store.dispatch("alert", {
            type: "error",
            message: error.body.return_code ? this.$t(error.body.return_code) : this.$t("api.unknown_error")
          });
        })
        .finally(() => {
          this.isSubmitting = false;
          this.$store.dispatch("setNotBusy");
        });
    }
  }
};
</script>
