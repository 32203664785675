<template>
  <div tabindex="0" @keyup.enter="locateMe">
    <a class="a-button --secondary --block --location" @click="locateMe">
      Locate Me
    </a>
  </div>
</template>

<script>
export default {
  name: "InputGeocode",
  props: {
    value: {
      required: true
    }
  },
  data() {
    return {
      country: "",
      postalCode: "",
      latitude: undefined,
      longitude: undefined
    };
  },
  computed: {},
  methods: {
    async locateMe() {
      this.$emit("input", {});

      if (navigator.geolocation) {
        await this.$store.dispatch("setBusy");

        await navigator.geolocation.getCurrentPosition(
          async position => {
            this.latitude = position.coords.latitude;
            this.longitude = position.coords.longitude;

            await this.$api
              .get("/api/geocode-reverse", {
                latitude: this.latitude,
                longitude: this.longitude
              })
              .then(response => {
                this.$emit("input", {
                  country_code: response.body.country_code,
                  postal_code: response.body.postal_code,
                  latitude: this.latitude,
                  longitude: this.longitude
                });
              })
              .catch(error => {
                this.$store.dispatch("alert", {
                  type: "error",
                  message: "Could not get location. Please try again or enter manually."
                });
              })
              .finally(this.$store.dispatch("setNotBusy"));
          },
          () => {
            this.$store.dispatch("alert", {
              type: "error",
              message:
                "Unable to get your location through the browser. Please enter manually."
            });

            this.$store.dispatch("setNotBusy");
          },
          { maximumAge: 600000, timeout: 5000, enableHighAccuracy: true }
        );
      }
    }
  }
};
</script>
