<template>
    <section id="target-guidelines" class="guidelines-region primary-content primary-content-guidelines"
             aria-labelledby="control-guidelines" v-if="selectedProgram && selectedNavigationIndex === navigationIndex">
        <h3 class="guidelines-program-name">{{ selectedProgram.name }}</h3>
        <div class="guidelines-inner">

            <div v-if="selectedProgram.thumbnail" class="program-thumbnails">
                <img v-for="thumbnail in selectedProgram.thumbnail" :src="thumbnail.thumbnail_url" />
            </div>

            <p v-if="selectedProgram.is_startable" class="deadline-title">Deadline: <span class="deadline-date">{{ selectedProgram.deadline_date }}</span></p>

            <h4 class="guidelines-title">Guidelines</h4>

            <div class="richtextviewer" v-html="selectedProgram.guidelines"></div>

            <a v-if="selectedProgram.is_startable" class="program-apply-button" :href="applyToProgramLink">{{ `${ctaButton} ${selectedProgram.name}` }}</a>
        </div>
    </section>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        name: 'ProgramGuidelines',
        props: {
            navigationIndex: {
                type: Number,
                default: 1,
            },
            ctaButton: {
                type: String,
                required: true,
            }
        },
        computed: {
            ...mapState('organizationLanding', ['selectedProgram', 'selectedNavigationIndex', 'organizationId', 'isDelegate']),
          applyToProgramLink() {
              if (this.isDelegate) {
                return `/delegate/applicationstart/${this.selectedProgram.id}`
              }

            return `/applicationstart/${this.selectedProgram.id}?org=-1`;
          }
        }
    }
</script>

<style lang="scss" scoped>
    #target-guidelines {
        width: 100%;
    }
</style>
