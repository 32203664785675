<template></template>
<script>
export default {
  name: "UserLogout",
  mounted() {
    localStorage.clear();
    if (localStorage.getItem("jwt")) {
      localStorage.removeItem("jwt");
    }
    if (localStorage.getItem("contexts")) {
      localStorage.removeItem("contexts");
    }
    window.location.href = "/login" + window.location.hash;
  }
};
</script>

<style scoped></style>
