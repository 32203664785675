<template>
  <transition
    :name="direction" mode="out-in" :key="stepNumber"
    v-on:before-enter="hideOverflow"
    v-on:after-enter="revertOverflow"
  >
    <div class="stepper__step" v-show="active">
      <slot></slot>
    </div>
  </transition>
</template>

<script>
/**
 * StepperStep: A component that wraps any content that will be inserted into the StepperWizard.
 * @note see StepperWizard.vue for example usage
 *
 * @prop {int} stepNumber - a required prop determining when the content inside this step should be shown (based on StepperWizard's current step)
 */

export default {
  name: "StepperStep",
  /** injected state from the parent StepperWizard */
  inject: ["stepperState"],
  props: {
    stepNumber: {
      type: Number,
      required: true
    }
  },
  computed: {
    active() {
      return this.stepperState.currentStep === this.stepNumber;
    },
    direction() {
      return this.stepperState.slideDirection
        ? "slide-" + this.stepperState.slideDirection
        : "slide-next";

      // todo clean up after testing (also cleanup styles below)
      // ? "fade-" + this.stepperState.slideDirection
      //   : "fade-next";
    }
  },
  created() {},
  mounted() {},
  methods: {
    // todo better solution? after struggling longer than I'll admit this is all I could come up with... :/
    hideOverflow() {
        const app = document.getElementById("vue-app");
        if (app) {
            app.style.overflow = "hidden";
        }
    },
    revertOverflow() {
        const app = document.getElementById("vue-app");
        if (app) {
            app.style.overflow = "auto";
        }
    }
  }
};
</script>

<style scoped lang="scss">
// todo: clean up these shared styles (if they get used)

.slide-next-leave-active,
.slide-previous-leave-active {
  // ease in when leaving
  transition: all 0.5s ease-in;
}

.slide-next-enter-active,
.slide-previous-enter-active {
  // ease out when entering
  transition: all 0.5s ease-out;
}

.slide-next-enter {
  transform: translateX(100vw) scale(0.5);
  opacity: 0;
}
.slide-next-leave-to {
  transform: translateX(-100vw) scale(0.5);
  opacity: 0;
}

.slide-previous-enter {
  transform: translateX(-100vw) scale(0.5);
  opacity: 0;
}
.slide-previous-leave-to {
  transform: translateX(100vw) scale(0.5);
  opacity: 0;
}

.slide-next-enter-active,
.slide-previous-enter-active {
  position: relative;
  top: 0;
  width: 100%;
}
.slide-previous-leave-active,
.slide-next-leave-active {
  position: absolute;
  top: 0;
  width: 100%;
}

/**
 * todo clean up after testing (also clean up computed property above)
 */
.fade-previous-enter-active,
.fade-previous-leave-active,
.fade-next-enter-active,
.fade-next-previous-active {
  transition: opacity 0.5s;
}

.fade-next-enter,
.fade-previous-enter,
.fade-next-leave-to,
.fade-previous-leave-to {
  opacity: 0;
  position: relative;
  top: 0;
}
</style>
