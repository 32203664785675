/* eslint-disable no-param-reassign */
import api from "Api";

let initialState = {};
try {
  initialState = JSON.parse(window.__INITIAL_REPORTING__);
} catch (e) {
  // If it doesn't parse, ignore it.
}

const emptyState = {
  submittedByDate: []
};

export default {
  namespaced: true,
  state: Object.assign({}, emptyState, initialState),
  mutations: {
    setSubmittedByDate: (state, data) => {
      state.submittedByDate = data;
    }
  },
  actions: {
    getSubmittedByDate: ({ commit }) => {
      api.get("/api/reporting/submitted_by_time").then(response => {
        commit("setSubmittedByDate", response.body);
      });
    },
  }
};
