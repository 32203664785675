/**
 * This class contains utility methods related to routing including some items
 * copied from the SPA bundle to avoid importing unnecessary JS libraries.
 */
export default class RoutingUtility {
  /**
   * Go to a new route
   *
   * @param route
   * @returns {Function}
   */
  goToRoute = route => {
    return () => {
      window.location.href = route;
    };
  };

  /**
   * Get Api URL for relative URL.
   *
   * @param relativeUrl
   * @returns {*}
   */
  getApiUrl = relativeUrl => {
    return (process.env.API_URL || "") + relativeUrl;
  };

  /**
   * Get query parameter value by name. This was copied from the
   * javasciptutils.js module to use without JQuery.
   *
   * @param name
   * @returns {*}
   */
  getParameterByName = name => {
    // eslint-disable-next-line no-param-reassign
    name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
    const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);

    const results = regex.exec(window.location.search);
    return results === null
      ? null
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  };

  /**
   * Guess context ID from url parameter.
   *
   * @returns {string|null}
   */
  guessContextId = () => {
    const matches = window.location.href.match(/org=(\d+)/);
    if (!matches) return null;
    return matches[1];
  };
}
