/* eslint-disable no-param-reassign */
/* eslint-disable */
import api from "Api";

let initialState = {};
try {
  initialState = JSON.parse(window.__INITIAL_ORGANIZATION_DASHBOARD_STATE__);
} catch (e) {
  // If it doesn't parse, ignore it.
}

const emptyState = {
  applicationStats: {
    submission_total: null,
    in_progress: null,
    need_review: null,
    days: null
  },
  programStats: {
    next_deadline: null,
    next_deadline_tz: "America/New_York",
    not_yet_open: null,
    open: null,
    closed: null
  },
  programList: null,
  programListSortDirection: null,
  programListSortColumn: null,
  auditionRoomUserStats: {
    upcoming_sessions: null,
    lobbies: null,
    filled_timeslots: null,
    total_timeslots: null
  },
  recruitStats: {
    bookmarked: null,
    campaign_clickthrough: null,
    read_messages: null,
    responses: null,
    sent_messages: null,
    viewed: null
  },
  userAssignments: null
};

export default {
  namespaced: true,
  state: Object.assign({}, emptyState, initialState),
  mutations: {
    setApplicationStats: (state, data) => {
      state.applicationStats = data;
    },
    setProgramStats: (state, data) => {
      state.programStats = data;
    },
    setProgramList: (state, data) => {
      state.programList = data;
    },
    setProgramListSort: (state, { sortDirection, sortColumn }) => {
      state.programListSortDirection = sortDirection;
      state.programListSortColumn = sortColumn;
    },
    setAuditionRoomUserStats: (state, data) => {
      state.auditionRoomUserStats = data;
    },
    setUserAssignments: (state, data) => {
      state.userAssignments = data;
    }
  },
  actions: {
    getApplicationStats: ({ commit }) => {
      api.get("/api/dashboard/application-stats").then(response => {
        commit("setApplicationStats", response.body);
      });
    },
    getProgramStats: ({ commit }) => {
      api.get("/api/dashboard/program-stats").then(response => {
        commit("setProgramStats", response.body);
      });
    },
    setProgramListSort: ({ commit, state, dispatch }, { sortDirection, sortColumn }) => {
      commit("setProgramListSort", { sortDirection, sortColumn });
      dispatch("getProgramList");
    },
    getProgramList: ({ commit, state }) => {
      const options = {};
      if (state.programListSortColumn) {
        options.sort_column = state.programListSortColumn;
      }
      if (state.programListSortDirection) {
        options.sort_direction = state.programListSortDirection;
      }
      api.get("/api/dashboard/program-list", options).then(response => {
        // This response is paginated, and we just want the first page.
        commit("setProgramList", response.body.data);
      });
    },
    getAuditionRoomUserStats: ({ commit, state }) => {
      api.get("/api/dashboard/audition-room-user-stats").then(response => {
        commit("setAuditionRoomUserStats", response.body);
      });
    },
    getUserAssignments: ({ commit, state }) => {
      api.get("/api/scheduling/my-assignments").then(response => {
        commit("setUserAssignments", response.body);
      });
    }
  }
};
