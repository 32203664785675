<template>
  <button
    v-if="!!username"
    type="button"
    class="bookmark-button color-contrast-high"
    :class="{ 'cursor-default': isTracked }"
    @click="handleClick"
    @mouseover="hoveredOrFocused = true"
    @mouseleave="hoveredOrFocused = false"
    @focusin="hoveredOrFocused = true"
    @focusout="hoveredOrFocused = false"
    :aria-pressed="isTracked"
>
    <check-icon v-if="isTracked" size="xs" class="color-primary-dark" style="position: relative; top: -1px;" solid />
    <plus-sm-icon v-else :style="{ color: hoveredOrFocused && !isTracked ? 'var(--color-primary-dark)' : '' }" size="xs" style="position: relative; top: -1px;" solid />
    <span class="text-uppercase text-sm letter-spacing-md font-bold">{{ isTracked ? "Added" : "Add to List" }}</span>
  </button>
</template>

<script>
import { mapActions, mapState } from "vuex";
import UnfilledHeart from "LegacyJS/../images/svg/heart-4C4C4C.svg";
import FilledHeart from "LegacyJS/../images/svg/heart-full-FFB6C1.svg";
import CheckIcon from "Components/icons/CheckIcon.vue";
import PlusGrayIcon from "LegacyJS/../images/svg/plus-808080.svg";
import PlusGreenIcon from "LegacyJS/../images/svg/plus-5ACE9E.svg";
import PlusSmIcon from "Components/icons/PlusSmIcon.vue";

export default {
  name: "Favorite",
  components: {
    CheckIcon,
    PlusGrayIcon,
    PlusGreenIcon,
    PlusSmIcon,
    UnfilledHeart,
    FilledHeart
  },
  data() {
    return {
      hoveredOrFocused: false
    };
  },
  computed: {
    ...mapState({
      username: state => state.username,
      isTracked: state => state.organizationLanding.isTracked,
      organizationId: state => state.organizationLanding.organizationId,
      userId: state => state.me.user_id
    })
  },
  methods: {
    ...mapActions("organizationLanding", ["createOpportunity"]),
    handleClick() {
      if (!this.isTracked) {
        this.createOpportunity({ organizationId: this.organizationId, userId: this.userId });
      }
    }
  }
};
</script>
