import i18n from "i18next";

import englishTrans from "../i18n/en/translation.json";

export default () =>
  i18n.init({
    fallbackLng: "en",
    // Somewhere between v11 and v19 of i18next, a translation that results in an object
    // no longer returned null when returnObjects is not defined.
    // Instead an error string is returned. Tablevel field models translate depends
    // on a null being returned.
    returnedObjectHandler: () => null,
    resources: {
      en: {
        translation: englishTrans,
      },
    },
  });
