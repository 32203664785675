<template>
  <section class="t-guest">
    <div class="t-guest__logo"></div>

    <div class="t-guest__content --login card">
      <div class="padding-lg">
        <h1 class="t-guest__heading">{{ $t("user.userlogin.acceptd") }}</h1>

        <validation-observer v-slot="{ invalid }" ref="validationObserver">
          <form ref="loginForm" @submit.prevent="loginSubmit" novalidate>
            <div class="o-formGroup">
              <label class="o-formGroup__label" for="login-username">
                {{ $t("user.userlogin.username") }}
              </label>
              <input-text
                v-model="usernames"
                rules="required"
                id="login-username"
                name="username"
                validation-display-name="email"
                autocapitalize="off"
                autocomplete="username email"
                autocorrect="off"
                spellcheck="false"
                @blur="usernameIsTouched = true"
                autofocus
              ></input-text>
            </div>

            <div class="o-formGroup">
              <label class="o-formGroup__label" for="login-password">
                {{ $t("user.userlogin.password") }}
              </label>
              <input-revealable-text
                v-model="password"
                rules="required"
                name="password"
                validation-mode="aggressive"
                id="login-password"
                @blur="passwordIsTouched = true"
                autocomplete="current-password"
              ></input-revealable-text>
            </div>

            <button class="a-button --primary --block" ref="loginButton" :disabled="invalid" type="submit">
              {{ $t("user.userlogin.login") }}
            </button>
          </form>
        </validation-observer>
        <a class="t-guest__secondaryC2a" :href="router.getApiUrl('/forgot_password')">
          {{ $t("user.userlogin.forgotpassword") }}
        </a>
      </div>
    </div>

    <p class="t-guest__subtext">
      {{ $t("user.userlogin.swap.question") }}
      <a :href="router.getApiUrl('/signup')">
        {{ $t("user.userlogin.swap.link") }}
      </a>
    </p>
  </section>
</template>

<script>
// todo: handle screen reader text for processing.
import Auth from "../auth";
import Router from "js/global/routingutility";
import ErrorUtility from "../error";
import Session from "../session";
import InputText from "Components/form/InputText.vue";
import InputRevealableText from "Components/form/InputRevealableText.vue";

const auth = new Auth();
const errorUtility = new ErrorUtility();
const router = new Router();
const session = new Session();

export default {
  name: "UserLogin",
  components: { InputText, InputRevealableText },
  data() {
    return {
      usernames: router.getParameterByName("skew") ? router.getParameterByName("username") : "",
      password: "",
      isSubmitting: false,
      passwordIsTouched: false,
      showPassword: false,
      usernameIsTouched: false,
      router: router
    };
  },
  computed: {
    username() {
      return this.usernames.split(" ")[0];
    },
    target() {
      return this.usernames.split(" ")[1];
    }
  },
  methods: {
    loginSubmit: async function() {
      this.isSubmitting = true;
      await this.$store.dispatch("setBusy");

      const payload = {
        username: this.username,
        password: this.password
      };

      if (this.target) {
        payload.target = this.target;
      }

      this.$api
        .post("/login", payload)
        .then(response => {
          if (session.getTokenUsage(response.body.token) >= 1) {
            return Promise.reject({
              result: { return_code: "user.userlogin.errorTime" }
            });
          } else {
            if (window.google_tag_manager && window.gtag && typeof window.gtag === 'function') {
              gtag('event', 'login', {
                methods: 'Form',
                'event_callback': () => {
                  auth.handleToken(response.body.token, response.body.contexts);
                  window.location.href = window.location.hash.substring(1) || "/dashboard";
                }
              });
            } else {
              auth.handleToken(response.body.token, response.body.contexts);
              window.location.href = window.location.hash.substring(1) || "/dashboard";
            }
          }
        })
        .catch(error => {
          // checks if unverified so we can display a static link to re-send verification
          if (error.status === 419) {
            this.$store.dispatch("alert", {
              type: "error",
              message: error.message
            });
          } else if (error.status === 422) {
            this.$refs.validationObserver.setErrors({
              email: error.body.errors.username,
              ...error.body.errors
            });
          } else {
            this.$store.dispatch("alert", {
              type: "error",
              message: error.body.message
            });
          }
        })
        .finally(() => {
          this.$store.dispatch("setNotBusy");
          this.isSubmitting = false;
        });
    }
  }
};
</script>
