<template>
  <!-- if less than our max number to display, show the list -->
  <ul v-if="displayList" class="c-auditionLobbyCard__groupMemberList studentAmbassadorUserList">
    <li v-for="user in users" class="c-auditionLobbyCard__groupMemberListItem">
      {{ `${user.first_name} ${user.last_name}` }}
    </li>
  </ul>

  <!-- else display a link that opens a modal with the list -->
  <div v-else class="studentAmbassadorUserList">
    <a role="button" class="" @click="showingModal = true">View all {{ users.length }}</a>
    <modal :open="showingModal" @close="showingModal = false">
      <ul class="c-auditionLobbyCard__groupMemberList">
        <li v-for="user in users" class="c-auditionLobbyCard__groupMemberListItem">
          {{ `${user.first_name} ${user.last_name}` }}
        </li>
      </ul>
    </modal>
  </div>
</template>

<script>
  import Modal from "Components/common/Modal.vue";

  export default {
    name: "AmbassadorUserList",
    components: { Modal },
    props: {
      users: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        maxUsersToDisplay: 4,
        showingModal: false
      };
    },
    computed: {
      displayList() {
        return this.users.length <= this.maxUsersToDisplay;
      }
    }
  };
</script>
