<template>
  <div class="social-loader__container" ref="content">
    <div ref="placeholder" class="social-loader__placeholder" :class="{ '--visible': !hasLoaded }">
      <div class="banner"></div>
      <div class="card">
        <div class="image"></div>
        <h4 class="text"></h4>
        <h4 class="text line"></h4>
        <hr>
        <h4 class="text line"></h4>
      </div>
      <div class="card">
        <div class="image"></div>
        <h4 class="text line"></h4>
        <h4 class="text"></h4>
        <hr>
        <h4 class="text line"></h4>
      </div>
    </div>

    <div ref="slot" class="social-loader__slot" :class="{ '--visible': !!hasLoaded }">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "SocialLoader",
  data() {
    return {
      observer: new MutationObserver(this.onPluginLoaded),
      config: { childList: true, subtree: true },
      hasLoaded: false
    };
  },
  methods: {
    onPluginLoaded(mutationsList, observer) {
      this.hasLoaded = true;
    }
  },
  mounted() {
    this.observer.observe(this.$refs.slot, this.config);
  }
};
</script>

<style lang="scss" scoped>
.social-loader {

  &__container {
    position: relative;
    width: 100%;
    min-height: 500px;
    margin-bottom: 1.5rem;
  }

  &__placeholder {
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
    font-size: 0;
    line-height: 0;
    transition: opacity 200ms ease-out 700ms;
    overflow: scroll;

    &.--visible {
      opacity: 0.3;
    }

    hr {
      opacity: 0.2;
    }

    .card {
      box-shadow: 0 1px 2px rgba(0, 0, 0, .12);
      margin-bottom: 0.5rem;
      background-color: #fff;
    }

    .text {
      display: inline-block;
      background-color: #444;
      height: 12px;
      border-radius: 100px;
      margin: 5px 0;
      min-width: 100px;
      opacity: .1;
      animation: fading 1.5s infinite;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &.line {
        width: 100%;
      }
    }

    .banner {
      width: 100%;
      background-color: rgba(76, 85, 102, 0.3);
      margin-bottom: 10px;
      padding-top: 70px;
    }

    .image {
      width: 100%;
      height: 160px;
      background-color: rgb(76, 85, 102);
      animation: fading 1.5s infinite;
    }
  }

  &__slot {
    position: relative;
    transition: opacity 350ms ease-in 750ms;
    opacity: 0;

    &.--visible {
      opacity: 1;
    }
  }

  &__placeholder {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;

  }
}

@keyframes fading {
  0% {
    opacity: .3;
  }

  50% {
    opacity: .4;
  }

  100% {
    opacity: .3;
  }
}
</style>
