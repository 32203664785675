<template>
  <div class="create-applicant mini-content">
    <a class="mini-content-anchor" href="/">
        <span class="screen-reader-text">Return to home</span>
    </a>
    <div class="mini-content-container">
      <div class="mini-content-inner">
        <div class="create-applicant-inner content-mini-inner">
          <h1 class="content-mini-heading">
            Acceptd Terms of Use and Privacy Policy
          </h1>
          <br>
          <p class="create-applicant-description">
            Please review and accept the current
            <a href="https://getacceptd.com/terms-of-use" target="_blank" rel="noopener noreferrer">Terms of Use</a>
            and
            <a href="https://getacceptd.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
            before continuing.
            Visit our <a href="https://support.getacceptd.com" target="_blank">support page</a> if you have any questions.
          </p>
          <form method="POST" action="/terms" @submit.prevent>
            <label class="control checkbox" required="">
              <input
                v-model="isAccepting"
                id="terms-check-box"
                name="accepts_terms"
                value="1"
                type="checkbox"
              />
              <span class="control-indicator"></span>&nbsp;
              <span>
                I agree to Acceptd’s
                <a href="https://getacceptd.com/terms-of-use" target="_blank" rel="noopener noreferrer">Terms of Use</a>
                and
                <a href="https://getacceptd.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
              </span>
            </label>
            <button
              :disabled="!isAccepting"
              type="button"
              @click="update"
              class="btn btn-primary"
            >
              Accept and Continue
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Auth from "../auth";

export default {
  name: "AcceptTermsForm",
  data() {
    return {
      isAccepting: false,
      auth: new Auth()
    };
  },
  methods: {
    update: async function() {
      this.$store.dispatch("setBusy");
      this.$api
        .post("/terms", { accepts_terms: this.isAccepting })
        .then(response => {
          this.auth.handleToken(response.body.token, response.body.contexts);
          window.location.href =
            window.location.hash.substring(1) || "/dashboard?s=/terms";
        })
        .catch(error => {
          // checks if unverified so we can display a static link to re-send verification
          this.$store.dispatch("alert", {
            type: "error",
            message: error.body.message
          });
        })
        .finally(() => {
          this.$store.dispatch("setNotBusy");
        });
    }
  }
};
</script>
