<template>
  <transition name="fade">
    <div v-if="message" role="alert" class="m-growlAlert" :class="modifier">
      <ul v-if="isArray">
        <li v-for="message in flashData.message" v-html="message"></li>
      </ul>
      <div v-else v-html="message"></div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "AlertMessage",
  props: {
    flashData: {
      required: true
    },
    alertDuration: {
      type: Number,
      default: 5
    }
  },
  computed: {
    message() {
      return this.$store.getters.alertMessage;
    },
    isArray() {
      return this.flashData && Array.isArray(this.flashData.message);
    },
    modifier() {
      return this.$store.getters.alertType ? `--${this.$store.getters.alertType}` : "";
    }
  },
  mounted() {
    if (this.flashData && this.flashData.hasOwnProperty("message")) {
        this.$store.dispatch("alert", {
          message: this.flashData.message,
          type: this.flashData.type,
          duration: this.alertDuration
        });
    }
  }
};
</script>

<style scoped lang="scss">
  .fade-enter-active, .fade-leave-active {
    transition: opacity .4s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>
