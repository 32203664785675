<template>
  <section class="t-guest">
    <div class="t-guest__logo"></div>

    <form action="/signup/applicant" method="POST" ref="form">
      <input type="hidden" name="_token" :value="$csrfToken" />
      <stepper-wizard :step-override="stepOverride">
        <template v-slot:aboveSteps="{ stepperState }">
          <timeline
            :current-step="stepperState.currentStep"
            :total-steps="stepperState.totalSteps"
            class="t-guest__timeline"
            @setStep="overrideCurrentStep"
          >
            <template slot="itemCaption-1">
              Account
            </template>
            <template slot="itemCaption-2">
              Details
            </template>
          </timeline>
        </template>

        <template v-slot:steps="{ stepperState }">
          <stepper-step :step-number="1">
            <div class="t-guest__content card">
              <div class="padding-lg">
                <validation-observer v-slot="{ invalid }" ref="validationObserver">
                  <div>
                    <h1 class="t-guest__heading">Sign up with your email</h1>

                    <!-- displays a full listing of errors sent back from server (if applicable)-->
                    <ul class="o-formGroup" v-if="hasErrors">
                      <li class="o-formGroup__error" v-for="error in errors">{{ error[0] }}</li>
                    </ul>

                    <div class="o-formGroup">
                      <label class="o-formGroup__label" for="email">Email</label>
                      <input-text
                        v-model="username"
                        name="username"
                        rules="email|required"
                        validation-display-name="email"
                        id="email"
                      ></input-text>
                    </div>

                    <div class="o-formGroup">
                      <label class="o-formGroup__label" for="password">Password</label>
                      <create-password
                        v-model="password"
                        id="password"
                        @validate="e => (passwordValid = e)"
                      ></create-password>
                    </div>

                    <div class="o-formGroup__actions">
                      <a class="a-button --tertiary o-formGroup__action" href="/signup">Back</a>
                      <button
                        class="a-button --primary o-formGroup__action"
                        type="button"
                        @click="stepperState.nextStep"
                        :disabled="invalid || !passwordValid"
                      >
                        Next Step
                      </button>
                    </div>
                  </div>
                </validation-observer>
              </div>
            </div>
          </stepper-step>

          <stepper-step :step-number="2">
            <div class="t-guest__content card">
              <div class="padding-lg">
                <create-student
                  :questions="questions[0]"
                  @back="stepperState.previousStep"
                  @done="handleSubmit"
                  :old-input="oldInput"
                  :errors="errors"
                ></create-student>
              </div>
            </div>
          </stepper-step>
        </template>
      </stepper-wizard>
    </form>
  </section>
</template>

<script>
import InputText from "Components/form/InputText.vue";
import CreateStudent from "Components/sign-up/CreateStudent.vue";
import CreatePassword from "Components/sign-up/CreatePassword.vue";
import StepperWizard from "Components/common/StepperWizard.vue";
import StepperStep from "Components/common/StepperStep.vue";
import Timeline from "Components/common/Timeline.vue";

export default {
  name: "SignUpApplicant",
  components: {
    CreateStudent,
    InputText,
    CreatePassword,
    StepperWizard,
    StepperStep,
    Timeline
  },
  props: {
    questions: {
      type: Array,
      required: true
    },
    oldInput: {
      required: false,
      default: () => ({})
    },
    errors: {
      required: false,
      default: () => ({})
    }
  },
  data() {
    return {
      username: this.oldInput.username,
      password: "",
      passwordValid: false,
      stepOverride: undefined /** only used if we want to override the current step */
    };
  },
  computed: {
    stepOneComplete() {
      return this.username && this.passwordValid;
    },
    hasErrors() {
      return Object.keys(this.errors).length;
    }
  },
  mounted() {
    if (this.hasErrors) {
      this.$refs.validationObserver.setErrors({
        email: this.errors.username,
        ...this.errors
      });
    }
  },
  methods: {
    /**
     * Force an override on the current step (if allowed). Since we only have 2 steps (as of now) we can just ensure
     * step 1 is complete before forcing override. (this handles clicks on the timeline icons for navigation)
     *
     * @param event - payload from the setStep event from the Timeline component
     */
    overrideCurrentStep(event) {
      if (event === 1) {
        this.stepOverride = 1;
      } else if (event === 2 && this.stepOneComplete) {
        this.stepOverride = 2;
      }
    },

    handleSubmit(event) {
      if (window.google_tag_manager && window.gtag && typeof window.gtag === 'function') {
        gtag('event', 'sign_up', {
          method: 'Form',
          'event_callback': () => {
            this.$refs.form.submit();
          }
        });
      } else {
        this.$refs.form.submit();
      }
    }
  }
};
</script>
