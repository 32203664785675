export default class Session {
  getUser = token => {
    try {
      const user = window.mpApp.jwtDecode(token);
      user.id = user.sub; // subject is id
      return user;
    } catch (error) {
      return {};
    }
  };

  getTokenUsage = token => {
    const user = this.getUser(token);
    if (!user) return 0;
    return (Number(new Date()) / 1000 - user.iat) / (user.exp - user.iat);
  };
}
