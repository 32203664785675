/**
 * Legacy compression from SPA to get around context QuotaExceededError.
 *
 * @param contexts
 */
const compressContexts = contexts => {
  const newContexts = {};
  Object.keys(contexts).forEach(function each(key) {
    newContexts[key] = { ...contexts[key] };
  });
  const compressed = {};
  const permLookup = {};
  compressed.contexts = newContexts;
  compressed.permLookup = permLookup;

  const getPermIndex = function getPermIndex(perm) {
    if (Object.prototype.hasOwnProperty.call(permLookup, perm)) {
      return permLookup[perm];
    }
    const index = Object.keys(permLookup).length;
    permLookup[perm] = index;
    return index;
  };

  Object.keys(newContexts).forEach(function each(key) {
    const context = newContexts[key];
    const perms = context.permissions;
    const smallPerms = [];
    Object.keys(perms).forEach(function eachPerm(perm) {
      smallPerms[getPermIndex(perm)] = perms[perm] ? 1 : 0;
    });
    context.permissions = smallPerms;
  });

  return compressed;
};

/**
 * Authenticating within local storage.
 */
export default class Auth {
  handleToken = (jwt, context) => {
    localStorage.setItem("jwt", jwt);
    localStorage.setItem("contexts", JSON.stringify(compressContexts(context)));
  };
}
