<template>
  <validation-provider
    :rules="rules"
    v-slot="{ errors }"
    :name="validationDisplayName ? validationDisplayName : name"
    :mode="validationMode"
  >
    <input
      class="a-input"
      :type="type"
      :value="value"
      :name="name"
      :class="[{ '--invalid': errors.length }, classes]"
      v-bind="$attrs"
      v-on="inputListeners"
    />

    <slot name="belowInput"></slot>

    <slot v-if="errors.length" name="errors" :errors="errors">
      <p class="o-formGroup__error" v-html="errorMessage || errors[0]"></p>
    </slot>
  </validation-provider>
</template>

<script>
import FormInput from "./FormInput.js";

export default {
  name: "InputText",
  extends: FormInput,
  inheritAttrs: false,
  props: {
    type: {
      default: "text"
    }
  }
};
</script>
