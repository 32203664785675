<template>
    <section id="target-resources" class="primary-content primary-content-resources"
             aria-labelledby="control-resources" v-if="selectedNavigationIndex === navigationIndex">

        <div class="resources-inner">
            <h3>Resources</h3>
            <section class="resources-region">
                <div class="media questions">
                    <section class="media-items-region" role="region" aria-live="polite" aria-label="Media items">
                        <div v-for="resource in resources" class="media-item">
                            <a tabindex="0" :href="'/attachmentview/' + resource.id" target="_blank">
                                <div :aria-labelledby="`${resource.id}-description`" class="media-item-preview" :style="`background-image: url('${resource.thumbnail_url}');`"></div>
                                <p :id="`${resource.id}-description`">{{ resource.name }}</p>
                            </a>
                        </div>
                    </section>
                </div>
            </section>
        </div>
    </section>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        name: "Resources.vue",
        props: {
            navigationIndex: {
                type: Number,
                default: 2,
            },
        },
        computed: {
            ...mapState('organizationLanding', ["selectedNavigationIndex", "resources"])
        }
    }
</script>

<style scoped>

</style>
