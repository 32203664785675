<template>
  <div>
    <multiselect
      v-model="selections"
      :options="options"
      :searchable="searchable"
      :close-on-select="false"
      :max="max"
      :show-labels="false"
      placeholder="Pick a value"
      :multiple="multi"
      v-on="$listeners"
      :label="label"
      :track-by="trackBy"
      :disabled="disabled"
      :id="id"
    >

    <template slot="maxElements">
      <p class="o-formGroup__error">Max of {{ max }} selections.</p>
    </template>

    <template v-if="checkboxes" slot="selection" slot-scope="{ values, search, isOpen }">
      <span v-if="values.length &amp;&amp; !isOpen">{{ values.length }} of {{options.length}} selected</span>
    </template>
    <div v-if="checkboxes" slot="tag"></div>
    <div v-if="checkboxes" slot="option" slot-scope="scope" class="flex items-center color-contrast-high">
      <div class="multiselect-icon-wrap border border-contrast-high flex items-center justify-center">
        <svg v-if="selections.find(s => s.id == scope.option.id)" xmlns="http://www.w3.org/2000/svg" class="multiselect-icon" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
        </svg>
      </div>
      <span class="padding-left-xs">{{ scope.option[label] }}</span>
    </div>
      <!-- todo: max elements selected handling/slot-->

      <!--            <template slot="maxElements">-->
      <!--                <ul class="multiselect__content">-->
      <!--                    <li v-for="option in options" class="multiselect__option">-->
      <!--                        {{ option }}-->
      <!--                    </li>-->
      <!--                </ul>-->
      <!--            </template>-->
    </multiselect>
  </div>
</template>

<script>
/** todo: is this even wrapper necessary w/ current functionality? */

import Multiselect from "vue-multiselect";
export default {
  name: "InputMultiselect",
  components: {
    Multiselect,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    options: {
      required: true
    },
    searchable: {
      type: Boolean,
      default: false
    },
    multi: {
      type: Boolean,
      default: true
    },
    max: {
        required: false
    },
    label: {
      required: false,
      default: null,
    },
    checkboxes: {
      type: Boolean,
      default: false,
    },
    trackBy: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selections: [],
    };
  },
  watch: {
    '$attrs.value': {
      deep: true,
      immediate: true,
      handler: function (val) {
        if (val) {
          this.selections = val;
        }
      }
    }
  }
};
</script>
<style scoped>
  .multiselect-icon-wrap {
    width: 17px;
    height: 17px;
  }
  .multiselect-icon {
    width: 15px;
    height: 15px;
  }
  .multiselect__option--selected {
    font-weight: inherit;
  }
</style>
