import { configureScope, init, Integrations } from "@sentry/browser";

const initSentry = function initSentry(dsn) {
  init({
    dsn,
    environment: process.env.SENTRY_ENV,
    ignoreErrors: [
      /ViewDestroyedError:.*/,
      /Can't find variable: WeakMap/,
      "ResizeObserver loop limit exceeded",
      "Illegal invocation",
      /The selector you specified \(#card-number\) applies to no DOM elements that are currently on the page/,
      /.ru\/cc/,
      /.ru\/ooo_js/,
      /.ru\/adb_rotate/
    ],
    release: process.env.GIT_SHA,
    sampleRate: process.env.SENTRY_SAMPLE_RATE,
    integrations: [
      new Integrations.GlobalHandlers({
        onunhandledrejection: false
      })
    ]
  });
};

const setSentryContext = function setSentryContext({
  userId,
  username,
  roleId,
  organization,
  organizationId,
  isMasquerading
}) {
  configureScope(scope => {
    scope.setUser({ id: userId, username });
    scope.setTag("roleId", roleId);
    scope.setTag("isMasquerading", isMasquerading);
    if (organizationId > 0) {
      scope.setTag("organization", organization);
      scope.setTag("organizationId", organizationId);
    }
    /* eslint-disable compat/compat */
    if (navigator.connection) {
      const { downlink, downlinkMax, rtt, type } = navigator.connection;
      /* eslint-enable compat/compat */
      if (downlink) scope.setTag("networkInfoDownlink", downlink);
      if (downlinkMax) scope.setTag("networkInfoDownlinkMax", downlinkMax);
      if (rtt) scope.setTag("networkInfoRTT", rtt);
      if (type) scope.setTag("networkInfoType", type);
    }
  });
};

export { initSentry, setSentryContext };
