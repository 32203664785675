import i18next from "i18next";

/**
 * The utility class contains methods related to error messages, including
 * methods from the SPA bundle brought over in order to use without importing
 * unnecessary JS libraries.
 */
export default class ErrorUtility {
  /**
   * Consolidate API Messages into a single string.
   *
   * @param errorMessages
   * @returns {string}
   */
  consolidateAPIErrorMessages = errorMessages => {
    return Object.keys(errorMessages)
      .map(key => {
        if (Array.isArray(errorMessages[key]))
          return errorMessages[key].join("<br/>");
        return errorMessages[key];
      })
      .join("<br/>");
  };

  /**
   * Get API message from return code.
   *
   * @param response
   * @param result
   * @returns {{default: *}}
   */
  getAPIErrorMessagesFetch = (response, result) => {
    if (!result) {
      // no response
      return { default: i18next.t("api.unknown_error") };
    }
    // eslint-disable-next-line no-prototype-builtins
    if (result.hasOwnProperty("return_code")) {
      // flat return code
      return { default: i18next.t(result.return_code, result.data) };
    }
    return { default: i18next.t("api.unknown_error") };
  };
}
