<template>
  <icon-wrapper v-slot="{ solid }" v-bind="$attrs" v-on="$listeners">
    <svg v-if="solid" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
    </svg>
    <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
    </svg>
  </icon-wrapper>
</template>

<script>
import IconWrapper from "Components/icons/IconWrapper.vue";

export default {
  name: "CheckIcon",
  inheritAttrs: false,
  components: {
    IconWrapper
  }
};
</script>
