<template>
  <div class="stepper">
    <slot name="aboveSteps" :stepperState="stepperState"></slot>
    <div class="stepper__steps" ref="steps">
        <slot name="steps" :stepperState="stepperState"></slot>
    </div>
  </div>
</template>

<script>
/**
 * StepperWizard: A self-contained stepping component that exposes a slot for inserting StepperStep components that
 * show/hide content based on the current active step. All data (and methods) defined in the stepperState object are
 * exposed to the slots so any parents component can define their own markup for navigating steps.
 *
 * @prop {int} stepOverride - optional prop that allows a parent to force an override for the current step
 *
 * Example Usage:
 * <stepper-wizard>
 *   <template v-slot:steps="{ stepperState }">
 *     <stepper-step :step-number="1">... content to display for step #1 ...</stepper-step>
 *     <stepper-step :step-number="2">... content to display for step #2 ...</stepper-step>
 *
 *     <button @click="stepperState.previousStep">Back</button>
 *     <button @click="stepperState.nextStep">Next</button>
 *   </template>
 * <stepper-wizard>
 */

export default {
  name: "StepperWizard",
  /** provides this components state to the StepperStep children */
  provide() {
    return {
      stepperState: this.stepperState
    };
  },
  props: {
    /** prop connected to watcher to force a new current step in the shared state from the parent */
    stepOverride: {
      type: Number,
      default: undefined
    }
  },
  data() {
    return {
      stepperState: {
        currentStep: this.stepOverride ? this.stepOverride : 1,
        slideDirection: "",
        nextStep: this.nextStep,
        previousStep: this.previousStep,
        totalSteps: 0
      }
    };
  },
  computed: {
    numberSteps() {
      return this.$refs.steps.children.length;
    },
    onLastStep() {
      return this.stepperState.currentStep === this.numberSteps;
    }
  },
  mounted() {
    // force update the shared state so any parent slots can retrieve an accurate
    // number of steps that got counted once this was fully mounted
    this.stepperState.totalSteps = this.numberSteps;
  },
  methods: {
    nextStep() {
      if (!this.onLastStep) {
        this.stepperState.currentStep += 1;
        this.stepperState.slideDirection = "next";
      }
    },

    previousStep() {
      if (this.stepperState.currentStep > 1) {
        this.stepperState.currentStep -= 1;
        this.stepperState.slideDirection = "previous";
      }
    },

    isValidStep(step) {
      return !isNaN(step) && step >= 1 && step <= this.numberSteps;
    }
  },
  watch: {
    /**
     * Watcher for an optional step override prop. If this value gets set by the parent
     * (&is a valid step) we will force update the current step in this component's shared state
     * @param {int} val -
     */
    stepOverride(val) {
      if (this.isValidStep(val)) {
        // first we must set the slide direction appropriately
        this.stepperState.slideDirection = (val > this.stepperState.currentStep) ? "next" : "previous";
        this.stepperState.currentStep = val;
      }
    }
  }
};
</script>

<style scoped lang="scss">

/** todo: something with this ish... (is it event necessary?) */

.stepper {
  //overflow-x: hidden;
  //display: block;
}
.stepper__steps {
  position: relative;
  width: 100%;
}
</style>
