<template>
  <div>
    <div class="o-formGroup --dob">
      <validation-provider
        rules="required"
        v-slot="{ errors }"
        name="month"
        mode="eager"
        class="a-input --select"
        ref="validationMonth"
      >
        <select
          class="a-input --select"
          :class="{ '--invalid': underMinAge || errors.length || showServerErrors }"
          v-model="month"
          @change="handleChange"
          ref="month"
          :required="true"
        >
          <option value="" disabled selected>Month</option>
          <option value="01">January</option>
          <option value="02">February</option>
          <option value="03">March</option>
          <option value="04">April</option>
          <option value="05">May</option>
          <option value="06">June</option>
          <option value="07">July</option>
          <option value="08">August</option>
          <option value="09">September</option>
          <option value="10">October</option>
          <option value="11">November</option>
          <option value="12">December</option>
        </select>
        <p v-if="errors.length" class="o-formGroup__error">Required</p>
      </validation-provider>

      <validation-provider
        rules="required"
        v-slot="{ errors }"
        name="day"
        mode="eager"
        class="a-input --select"
        ref="validationDay"
      >
        <select
          class="a-input --select"
          :class="{ '--invalid': underMinAge || errors.length || showServerErrors }"
          v-model="day"
          @change="handleChange"
          ref="day"
        >
          <option value="" selected disabled>Day</option>
          <option v-for="day in days" :value="day">{{ parseInt(day) }}</option>
        </select>
        <p v-if="errors.length" class="o-formGroup__error">Required</p>
      </validation-provider>

      <validation-provider
        rules="required"
        v-slot="{ errors }"
        name="year"
        mode="eager"
        class="a-input --select"
        ref="validationYear"
      >
        <select
          class="a-input --select"
          :class="{ '--invalid': underMinAge || errors.length || showServerErrors }"
          v-model="year"
          @change="handleChange"
          ref="year"
        >
          <option value="" selected disabled>Year</option>
          <option v-for="year in years" :value="year">{{ year }}</option>
        </select>
        <p v-if="errors.length" class="o-formGroup__error">Required</p>
      </validation-provider>

      <!-- show errors (prioritize showing server errors first) -->
      <div v-if="showServerErrors" v-for="error in serverErrors" class="o-formGroup__error">{{ error }}</div>
      <p v-if="underMinAge && !showServerErrors" class="o-formGroup__error">
          If you are under the age of {{ minAge }}, a parent/guardian must complete your registration.
      </p>
    </div>

    <input v-if="embedHiddenInput" type="hidden" name="dob" :value="formattedDateString" />
  </div>
</template>

<script>
/**
 * InputDateOfBirth: renders 3 select boxes to choose month/day/year
 *
 * @prop string  value - requires a value from the parent (to use with v-model)
 * @emits event  input - event returns a "yyyy-mm-dd" formatted string (to use with v-model)
 */

export default {
  name: "InputDateOfBirth",
  props: {
    value: {
      type: String
    },
    embedHiddenInput: {
      type: Boolean,
      default: false
    },
    minAge: {
      type: Number,
      default: null
    },
    serverErrors: {
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      year: "",
      month: "",
      day: "",
      showServerErrors: false
    };
  },
  mounted() {
    this.showServerErrors = !!this.serverErrors.length;
  },
  computed: {
    /**
     * Creates 31 days (w/ prepended "0" numbers less than 10) to populate select box
     * @return array
     */
    days() {
      let days = [];
      for (let i = 1; i <= 31; i++) {
        let formattedDay = i < 10 ? "0" + i.toString() : i.toString();
        days.push(formattedDay);
      }
      return days;
    },

    /**
     * Creates 100 years in descending order (from now) to populate select box
     * @return array
     */
    years() {
      let currentYear = new Date().getFullYear();
      let years = [];
      for (let i = currentYear; i >= currentYear - 100; i--) {
        years.push(i.toString());
      }
      return years;
    },

    /**
     * Formats the chosen values to send back on the input event (e.g. "yyyy-mm-dd")
     * @return string
     */
    formattedDateString() {
      return [this.year, this.month, this.day].join("-");
    },

    validDate() {
      let date = new Date(this.formattedDateString);
      return date instanceof Date && !isNaN(date);
    },

    underMinAge() {
      if (this.minAge && this.validDate) {
        const dob = new Date(this.formattedDateString);
        let check = new Date();
        check.setFullYear(check.getFullYear() - this.minAge);
        return dob >= check;
      }
      return false;
    }
  },

  methods: {
    isValidMonth(number) {
      return parseInt(number) > 0 && parseInt(number) < 13;
    },

    /** Emits the input event & returns the formatted date string */
    handleChange() {
      if (this.validDate) {
        // flip off any server errors passed in as soon as a valid date is supplied
        this.showServerErrors = false;
      }
      this.$emit("input", this.formattedDateString);
    }
  },

  watch: {
    /** Watch the value prop and attempt to parse so we can set the local select box selections */
    value: {
      immediate: true,
      handler(val) {
        if (val) {
          let dateArray = val.split("-");
          this.year = this.years.includes(dateArray[0]) ? dateArray[0] : "";
          this.month = dateArray[1] && this.isValidMonth(dateArray[1]) ? dateArray[1] : "";
          this.day = this.days.includes(dateArray[2]) ? dateArray[2] : "";
        }
      }
    }
  }
};
</script>
