<template>
  <ul class="navigation">
    <li data-content="overview" class="navigation-item">
      <button
        id="control-overview"
        type="button"
        :class="{ 'is-active' : isActive(0) || isActive(null) }"
        class="navigation-button"
        aria-controls="target-overview"
        @click="selectNavigation(0)"
      >
        <span class="screen-reader-text" data-toggle-string="Hide">Show</span>Overview
      </button>
    </li>

    <li data-content="guidelines" class="navigation-item" v-if="selectedProgram">
      <button
        id="control-guidelines"
        type="button"
        class="navigation-button"
        :class="{ 'is-active' : isActive(1) }"
        aria-controls="target-guidelines"
        @click="selectNavigation(1)"
      >
        <span class="screen-reader-text" data-toggle-string="Hide">Show</span>Guidelines
      </button>
    </li>
    <li data-content="resources" class="navigation-item" v-if="resources.length > 0">
      <button
        id="control-resources"
        type="button"
        :class="{ 'is-active' : isActive(2) }"
        class="navigation-button"
        aria-controls="target-resources"
        @click="selectNavigation(2)"
      >
        <span class="screen-reader-text" data-toggle-string="Hide">Show</span>Resources
      </button>
    </li>
  </ul>
</template>

<script>
  import { mapState, mapMutations } from "vuex";

  export default {
    name: "Navigation",
    computed: {
      ...mapState("organizationLanding", ["selectedProgram", "selectedNavigationIndex", "resources"])
    },
    methods: {
      ...mapMutations("organizationLanding", ["selectNavigation"]),
      isActive(index) {
        return this.selectedNavigationIndex === index;
      }
    }
  };
</script>
