/* eslint-disable no-param-reassign */
import api from "Api";
import OrganizationLandingModule from "./modules/organization-landing";
import OrganizationDashboardModule from "./modules/organization-dashboard";
import ReportingModule from "./modules/reporting";

let initialState = {};
try {
  initialState = JSON.parse(window.__INITIAL_STATE__);
} catch (e) {
  /** console.error("failed parsing initial state"); */
}

const emptyState = {
  username: "",
  me: "",
  organization: "",
  config: {},
  alertMessage: undefined,
  alertTimeout: undefined,
  alertType: undefined,
  unreadMessages: [],
  unreadMessageCount: 0,
  unsubmittedApplicationsCount: 0
};
/**
 * Create store for MPA Vue components.
 * Note: The SPA is using Redux, React Redux, and Redux Thunk.
 */

export const storeOptions = {
  state: Object.assign({}, emptyState, initialState),
  getters: {
    alertMessage: state => state.alertMessage,
    alertType: state => state.alertType
  },
  mutations: {
    alert: (state, data) => {
      // eslint-disable-next-line no-prototype-builtins
      const delay = data.hasOwnProperty("duration") ? data.duration : 5;
      clearTimeout(state.alertTimeout);
      state.alertMessage = data.message;
      state.alertType = data.type;
      state.alertTimeout = setTimeout(() => {
        state.alertMessage = undefined;
      }, delay * 1000);
    },
    setBusy: () => {
      // show spinner
      document.getElementById("ac-ldr").style.display = "flex";
      // set aria busy to true
      document.getElementsByTagName("body")[0].setAttribute("aria-busy", "true");
    },
    setNotBusy: () => {
      // show spinner
      document.getElementById("ac-ldr").style.display = "none";
      // set aria busy to true
      document.getElementsByTagName("body")[0].setAttribute("aria-busy", "false");
    },
    setOrganization: (state, organization) => {
      state.organization = organization;
    },
    setUnreadMessages: (state, messages) => {
      state.unreadMessages = messages;
    },
    setUnreadMessageCount: (state, count) => {
      state.unreadMessageCount = count;
    },
    setUnsubmittedApplicationCount: (state, count) => {
      state.unsubmittedApplicationsCount = count;
    }
  },
  actions: {
    alert: (state, data) => {
      state.commit("alert", data);
    },
    setBusy: state => {
      state.commit("setBusy");
    },
    setNotBusy: state => {
      state.commit("setNotBusy");
    },
    getUnreadMessages: state => {
      api.get("/api/messaging/threads", { unread: 1, unmuted: 1 }).then(response => {
        state.commit("setUnreadMessages", response.body.data);
        state.commit("setUnreadMessageCount", response.body.total);
      });
    },
    getUnreadMessageCount: state => {
      api.get("/api/threads/get_counts", { type: "unread" }).then(response => {
        state.commit("setUnreadMessageCount", response.body.unread);
      });
    },
    getUnsubmittedApplicationCount: state => {
      api.get("/api/applications/get_counts").then(response => {
        state.commit("setUnsubmittedApplicationCount", response.body.unsubmittedCount);
      });
    }
  },
  modules: {
    organizationLanding: OrganizationLandingModule,
    organizationDashboard: OrganizationDashboardModule,
    reporting: ReportingModule
  }
};

export default storeOptions;
