<template>
  <transition name="modal">
    <div 
      class="modal__overlay" 
      v-show="open" 
      @click="handleOverlayClick" 
      ref="overlay"
    >
      <div 
        ref="modalContent" 
        class="modal__content" 
        @click.stop 
        :role="ariaRole" 
        aria-modal="true"
      >
        <span 
          v-if="showCloseIcon" 
          role="button" 
          tabindex="0" 
          class="modal__close"      
          @click="closeModal" 
          @keyup.enter="closeModal"
          @keyup.space="closeModel"
          :aria-labelledby="label"
          :aria-describedby="description"
        >
          <svg class="icon icon--sm" viewBox="0 0 24 24">
            <title>Close modal window</title>
            <g fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
              <line x1="3" y1="3" x2="21" y2="21"/>
              <line x1="21" y1="3" x2="3" y2="21"/>
            </g>
          </svg>
          </span>
        <slot />
      </div>
    </div>
  </transition>
</template>

<script>
import trapFocus from "../../mixins/trapFocus"
export default {
  name: "Modal",
  mixins: [trapFocus],
  props: {
    open: {
      type: Boolean,
      required: true
    },
    preventScroll: {
      type: Boolean,
      default: true
    },
    closeOnOverlayClick: {
      type: Boolean,
      default: true
    },
    showCloseIcon: {
      type: Boolean,
      default: true
    },
    ariaRole: {
      type: String,
      default: "dialog"
    },
    label: {
      type: String,
      default: "modal-label"
    },
    description: {
      type: String,
      default: "modal-description"
    }
  },
  data() {
    return {
      escapeHandler: undefined,
    };
  },
  mounted() {
    this.$nextTick(() =>{
      this.trapFocus(this.$refs.modalContent);
    });
  },
  created() {
    this.escapeHandler = e => {
      if (e.key === "Escape" && this.open) {
        this.closeModal();
      }
    };
    document.addEventListener("keydown", this.escapeHandler);
  },
  destroyed() {
    document.removeEventListener("keydown", this.escapeHandler);
    if (document.body) {
      document.body.style.removeProperty("overflow");
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    handleOverlayClick() {
      if (this.closeOnOverlayClick) {
        this.closeModal();
      }
    }
  },
  watch: {
    open: {
      immediate: true,
      handler: function(open) {
        if (open && this.preventScroll) {
          document.body.style.setProperty("overflow", "hidden");
        } else {
          document.body.style.removeProperty("overflow");
        }
      }
    }
  }
};
</script>
