<template>
  <span class="icon" :class="`icon--${size.toLowerCase()}`" v-on="$listeners">
    <slot v-bind="{ size, solid }" />
  </span>
</template>

<script>
export default {
  name: "IconWrapper",
  props: {
    /**
     * Icon size following Codyhouse's icon classes.
     * https://codyhouse.co/ds/docs/framework/icons#sizes
     */
    size: {
      type: String,
      default: "sm",
      validator(val) {
        return ["xxs", "xs", "sm", "md", "lg", "xl"].includes(val.toLowerCase());
      }
    },

    /**
     * Display the Heroicon's "solid" version (defaults to "outline" version).
     */
    solid: {
      type: Boolean,
      default: false
    }
  }
};
</script>
