/* eslint-disable no-param-reassign */
import api from "Api";

let initialState = {};
try {
  initialState = JSON.parse(window.__INITIAL_ORGANIZATION_LANDING_STATE__);
} catch (e) {
  /** console.error("failed parsing initial module state for organization landing"); */
}

const emptyState = {
  selectedProgram: null,
  selectedNavigationIndex: 0,
  programs: [],
  resources: [],
  isTracked: false,
  organizationId: null,
  isDelegate: false
};

export default {
  namespaced: true,
  state: Object.assign({}, emptyState, initialState),
  mutations: {
    selectProgram: (state, program) => {
      state.selectedProgram = program;
      // TODO: This is the guidelines tab now, but the navigation component
      //       should be sending this mutation over, instead of the
      //       hard-coded magic value.
      state.selectedNavigationIndex = 1;
    },
    deselectProgram: state => {
      state.selectedNavigationIndex = null;
      state.selectedProgram = null;
    },
    selectNavigation: (state, index) => {
      state.selectedNavigationIndex = index;
    },
    addTracked: state => {
      state.isTracked = true;
    }
  },
  actions: {
    selectProgram: ({ commit }, program) => {
      // null when a selected program is unselected without
      // selecting a new program
      if (program === null) {
        commit("deselectProgram");
      } else {
        commit("selectProgram", program);
        api.post(`/programs/${program.id}/views`);
      }
    },
    createOpportunity({ commit }, { organizationId, userId }) {
      const payload = {
        user_id: userId,
        organization_id: organizationId
      };
      api
        .post("/api/application/internal-opportunities", payload)
        .then(() => commit("addTracked"))
        .catch(() => {
          /** console.error("failed removing bookmark") */
        });
    }
  }
};
