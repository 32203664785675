<template>
  <icon-wrapper v-slot="{ solid }" v-bind="$attrs" v-on="$listeners">
    <svg v-if="solid" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
      <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd" />
    </svg>
    <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
    </svg>
  </icon-wrapper>
</template>

<script>
import IconWrapper from "Components/icons/IconWrapper.vue";

export default {
  name: "PlusSmIcon",
  inheritAttrs: false,
  components: {
    IconWrapper
  }
};
</script>
