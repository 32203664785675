<template>
  <validation-observer v-slot="{ invalid }" ref="validationObserver">
    <div class="m-inputLocation">
      <!--   Create hidden inputs for form requests if options is set   -->
      <div v-if="renderHiddenInput">
        <input type="hidden" name="latitude" v-model="latitude" />
        <input type="hidden" name="longitude" v-model="longitude" />
        <input type="hidden" name="country_code" v-model="countryCode" />
        <input type="hidden" name="postal_code" v-model="postalCode" />
      </div>
      <div class="m-inputLocation__auto">
        <input-geocode
          class="m-inputLocation__coordinates"
          v-model="geocode"
        ></input-geocode>
        <div class="m-inputLocation__or">
          or
        </div>
        <div class="m-inputLocation__manualEntry">
          <input-select
            class="m-inputLocation__countrySelect"
            :options="countries"
            select-value="name"
            select-option="code"
            tracked-by="code"
            v-model="countryCode"
            placeholder="Enter My Country"
          ></input-select>
          <input-text
            v-if="countryCode === 'US'"
            aria-label="postal_code"
            id="postal_code"
            class="m-inputLocation__postalCode"
            name="postal_code"
            validation-display-name="zip code"
            rules="required|numeric|min:5|max:5"
            placeholder="Zip Code"
            v-model="postalCode"
          ></input-text>
        </div>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import InputGeocode from "./InputGeocode.vue";
import InputSelect from "./InputSelect.vue";
import countryData from "../../data/countries.js";
import InputText from "./InputText.vue";

// sort countries to preferred order
let sortedCountries = countryData.sort((a, b) => {
  if (a.code === "US") {
    return -5;
  } else if (b.code === "US") {
    return 1;
  } else if (a.code === "CA") {
    return -4;
  } else if (b.code === "CA") {
    return 1;
  } else if (a.code === "GB") {
    return -3;
  } else if (b.code === "GB") {
    return 1;
  } else if (a.code === "CN") {
    return -2;
  } else if (b.code === "CN") {
    return 1;
  } else if (a.name > b.name) {
    return 1;
  } else if (a.name < b.name) {
    return -1;
  }
});

export default {
  name: "InputUserLocation",
  components: {
    InputText,
    InputGeocode,
    InputSelect
  },
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    serverErrors: {
      required: false,
      default: () => []
    },
    renderHiddenInput: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      latitude: "",
      longitude: "",
      countryCode: "",
      postalCode: "",
      geocode: {},
      countries: sortedCountries,
      showServerErrors: false,
      setManually: false,
      postalCodeIsValid: false,
      detectedInvalidPostalCode: false,
      validPostalCode: undefined,
      invalidPostalCode: undefined
    };
  },
  mounted() {
    this.showServerErrors = !!this.serverErrors.length;
  },
  computed: {
    locationData() {
      return {
        latitude: this.latitude,
        longitude: this.longitude,
        countryCode: this.countryCode,
        postalCode: this.postalCode
      };
    }
  },
  watch: {
    locationData(value) {
      this.$emit("input", value);
    },
    countryCode(countryCode) {
      if (countryCode !== this.geocode.country_code) {
        this.geocode = {};
        this.postalCode = undefined;
        this.latitude = this.longitude = null;
      }
    },
    async postalCode(postalCode) {
      if (postalCode !== this.geocode.postal_code) {
        this.geocode = {};
        this.latitude = this.longitude = null;
      }

      if (postalCode && postalCode.length === 5) {
        await this.checkPostalCode();
        if (this.postalCode === this.invalidPostalCode) {
          this.$refs.validationObserver.setErrors({
            "zip code": ["Invalid U.S. zip code"]
          });
        }
      }
    },
    geocode(geocode) {
      if (
        geocode.latitude &&
        geocode.longitude &&
        geocode.country_code &&
        geocode.postal_code
      ) {
        this.latitude = geocode.latitude;
        this.longitude = geocode.longitude;
        this.countryCode = geocode.country_code;
        this.postalCode = geocode.postal_code;
      }

      if (this.latitude && this.longitude) {
        this.setManually = true;
      }
    }
  },
  methods: {
    async checkPostalCode() {
      await this.$api
        .get("/api/validate-postal-code", {
          postal_code: this.postalCode
        })
        .then(response => {
          if (response.body.valid_postal_code) {
            this.validPostalCode = response.body.postal_code;
          } else {
            this.invalidPostalCode = response.body.postal_code;
          }
        })
        .catch(error => {
          this.$store.dispatch("alert", {
            type: "error",
            message: error.body.message
          });
        });
    }
  }
};
</script>
