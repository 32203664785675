<template>
    <section
            id="target-overview"
            class="primary-content primary-content-overview"
            aria-labelledby="control-overview"
            v-if="selectedNavigationIndex === 0 || (selectedProgram === null && selectedNavigationIndex === null)"
    >
        <slot></slot>

        <div v-if="youtubeUrl.length && youtubeEmbedUrl" class="video-embed-container">
            <div class="video-embed">
                <div class="video-embed-ratio"></div>
                <iframe class="video-embed-video"
                        data-embed-type="video"
                        width="560"
                        height="315"
                        :src="`https://www.youtube.com/embed/${youtubeEmbedUrl}`"
                        frameborder="0"
                        allowfullscreen=""
                ></iframe>
            </div>
        </div>
    </section>
</template>

<script>
    import { mapState } from 'vuex'
    export default {
        name: "Overview",
        props: {
            navigationIndex: {
                type: Number,
                default: 0,
            },
            youtubeUrl: {
                type: String,
                default: ""
            }
        },
        computed: {
            ...mapState('organizationLanding', ['selectedNavigationIndex', 'selectedProgram']),
            youtubeEmbedUrl() {
                const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
                const match = this.youtubeUrl.match(regExp);
                if (match && match[7].length == 11) {
                    return match[7];
                }
                return null;
            }
        }
    }
</script>
