export default {
  methods: {
    trapFocus(element) {
      const focusableEls = Array.from(
        element.querySelectorAll(
          'a[href]:not([disabled]), [role="button"], button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])'
        )
      );

      const firstFocusableEl = focusableEls[0];
      const lastFocusableEl = focusableEls[focusableEls.length - 1];
      let currentFocus = null;

      firstFocusableEl.focus();
      currentFocus = firstFocusableEl;

      const handleFocus = e => {
        e.preventDefault();
        if (focusableEls.includes(e.target)) {
          currentFocus = e.target;
        } else {
          if (currentFocus === firstFocusableEl) {
            lastFocusableEl.focus();
          } else {
            firstFocusableEl.focus();
          }
          currentFocus = document.activeElement;
        }
      };

      document.addEventListener("focus", handleFocus, true);
    }
  }
};
